import { React, useState } from "react";
import "./style/income-tax-navigation.css";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/transparent-plus.svg";
import { ReactComponent as DivideIcon } from "../../../assets/svg/transparent-divide.svg";
import { ReactComponent as MultiplyIcon } from "../../../assets/svg/transparent-multiply.svg";
import IntroStep from "../intro-step/intro-step.component";

const IncomeTaxNavigation = ({ categories, currentStep, handleChange, fiscalPartner, progress }) => {
  const [expandedStates, setExpandedStates] = useState({
    box_1: false,
    box_2: false,
    box_3: false,
  });

  const toggleBox = (boxName) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [boxName]: !prevStates[boxName],
    }));
  };

  function isBoxExpanded(boxKey, steps, currentStep) {
    const isActive = Object.values(steps).some(
      (step) => step.order === currentStep
    );
    return expandedStates[boxKey] || isActive;
  }

  function getIndicatorClass(stepKey) {
    const stepStatus = progress[stepKey]?.status;

    switch (stepStatus) {
      case "active":
        return "selected active-indicator"; // Huidige stap
      case "done":
        return "selected done-indicator"; // ✅ Voorgaande stappen correct markeren
      case "not-started":
      default:
        return "selected not-started-indicator"; // Toekomstige stappen
    }
  }

  function getActiveClass(stepValue, currentStep) {
    if (currentStep === stepValue.order) {
      return "active";
    } else {
      return "inactive";
    }
  }

  function getBoxClass(boxKey) {
    const stepsInBox = Object.entries(progress).filter(
      ([stepKey]) => stepKey.startsWith(boxKey)
    );

    if (stepsInBox.every(([, step]) => step.status === "done")) {
      return "not-selected done-indicator";
    } else if (stepsInBox.some(([, step]) => step.status === "active")) {
      return "selected started-indicator";
    } else {
      return "not-selected not-started-indicator";
    }
    // const stepOrders = Object.values(steps).map((step) => step.order);
    // if (stepOrders.includes(currentStep)) {
    //   return "selected started-indicator";
    // } else if (stepOrders.every((order) => order < currentStep)) {
    //   return "not-selected done-indicator";
    // } else {
    //   return "not-selected not-started-indicator";
    // }
  }

  return (
    <div className="income-tax-navigation-container">
      {Object.entries(categories).map(([boxKey, boxValue]) => (
        <div key={boxKey} className="income-tax-navigation-box">
          <ul className="income-tax-navigation-items">
            <li
              key={boxKey}
              className={`income-tax-navigation-title ${getBoxClass(
                boxKey
              )}`}
              onClick={() => toggleBox(boxKey)}
            >
              <h2>{boxValue.title}</h2>
              <h4>{boxValue.subTitle}</h4>
            </li>
          </ul>
          <ul
            className={`income-tax-navigation-sub-items ${
              isBoxExpanded(boxKey, boxValue.steps, currentStep)
                ? "expanded"
                : ""
            }`}
          >
            {Object.entries(boxValue.steps).map(([stepKey, stepValue]) => (
              <li
                key={stepKey}
                className={`income-tax-navigation-sub-title ${getIndicatorClass(
                  stepKey
                )}`}
                onClick={() => handleChange(stepValue.order)}
              >
                <h3 className={`${getActiveClass(stepValue, currentStep)}`}>
                  Stap {stepValue.order}
                </h3>
                <h4 className={`${getActiveClass(stepValue, currentStep)}`}>
                  {stepValue.title}
                </h4>
              </li>
            ))}
          </ul>
          <div
            className="income-tax-navigation-collapse"
            onClick={() => toggleBox(boxKey)}
          >
            {expandedStates[boxKey] ? <ArrowUp/> : <ArrowDown/>}
          </div>
          <div className="income-tax-box-icon">
            {boxKey === "box_1" && <PlusIcon/>}
            {boxKey === "box_2" && <DivideIcon/>}
            {boxKey === "box_3" && <MultiplyIcon/>}
          </div>
        </div>
      ))}
      {!fiscalPartner && (
        <div className="income-tax-navigation-box">
          <ul className="income-tax-navigation-items">
            <li className="income-tax-navigation-title not-selected not-started-indicator">
              <h2>Fiscaal partner</h2>
              <h4 className="fiscal-partner-subtitle">Deel dit met uw fiscaal partner voor invullen van de gegevens</h4>
              <button className="income-tax-invite-partner-button">
                Delen
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )};

export default IncomeTaxNavigation;
