// BracketManager.js
import React from "react";
import InputComponent from "../../input-field/input-field.component";

function BracketManager({ brackets, updateBrackets, bracketType }) {
  const handleLocalChange = (index, field, value) => {
    const updatedBrackets = brackets.map((bracket, i) =>
      i === index ? { ...bracket, [field]: value } : bracket
    );
    updateBrackets(bracketType, updatedBrackets);
  };

  const addBracket = () => {
    updateBrackets(bracketType, [
      ...brackets,
      { min: 0, max: 0, percentage: 0, constant: 0 },
    ]);
  };

  const removeBracket = (index) => {
    updateBrackets(
      bracketType,
      brackets.filter((_, i) => i !== index)
    );
  };

  return (
    <div>
      {brackets.map((bracket, index) => (
        <div key={index}>
          <h4>Schijf {index + 1}</h4>
          <InputComponent
            label="Minimum"
            type="number"
            value={bracket.min || "0"}
            onChange={(e) => handleLocalChange(index, "min", e.target.value)}
          />
          <InputComponent
            label="Maximum"
            type="number"
            value={bracket.max || "0"}
            onChange={(e) => handleLocalChange(index, "max", e.target.value)}
          />
          <InputComponent
            label="Percentage"
            type="number"
            value={bracket.percentage || "0"}
            onChange={(e) =>
              handleLocalChange(index, "percentage", e.target.value)
            }
          />
          <InputComponent
            label="Constant"
            type="number"
            value={bracket.constant || "0"}
            onChange={(e) =>
              handleLocalChange(index, "constant", e.target.value)
            }
          />
          <button onClick={() => removeBracket(index)}>Verwijder</button>
        </div>
      ))}
      <button onClick={addBracket}>Voeg Toe</button>
    </div>
  );
}

export default BracketManager;
