// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-header {
    position: relative;
}

.input-pointer {
    position: absolute;
    left: -2rem;
    height: 1rem;
    width: 2rem;
    top: 0.4rem;
    margin-right: 4px;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.input-pointer.show {
    opacity: 1;
    transform: translateX(0);
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/animated-pointer-component/style/animated-pointer.style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,UAAU;IACV,4BAA4B;IAC5B,gEAAgE;AACpE;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,+BAA+B;AACnC;;AAEA;IACI;QACI,UAAU;QACV,4BAA4B;IAChC;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ","sourcesContent":[".input-header {\n    position: relative;\n}\n\n.input-pointer {\n    position: absolute;\n    left: -2rem;\n    height: 1rem;\n    width: 2rem;\n    top: 0.4rem;\n    margin-right: 4px;\n    opacity: 0;\n    transform: translateX(-10px);\n    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;\n}\n\n.input-pointer.show {\n    opacity: 1;\n    transform: translateX(0);\n    animation: fadeIn 0.3s forwards;\n}\n\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n        transform: translateX(-10px);\n    }\n    100% {\n        opacity: 1;\n        transform: translateX(0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
