import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        console.log("Stored user:", storedUser);
        if (storedUser) {
            setCurrentUser(JSON.parse(storedUser));
        } else {
            console.log("Geen opgeslagen gebruiker gevonden. Navigeren naar login.");
            setCurrentUser(null);
            navigate("/login");
        }
    }, [setCurrentUser, navigate]);

    const logout = useCallback(async () => {
        try {
            await axios.post(apiUrl + "/auth/logout", {}, {
                withCredentials: true,
            });
        } catch (error) {
            console.error("Uitloggen mislukt", error);
        } finally {
            localStorage.removeItem("user");
            setCurrentUser(null);
            navigate("/login");
        }
    }, [navigate]);

    const login = async (email, password) => {
        try {
            const response = await axios.post(
                apiUrl + "/auth/login",
                { email, password },
                { withCredentials: true }
            );
            const responseData = response.data.data;
            console.log("responseData", responseData)
            if (responseData.expiresAt) {
                const user = {
                    userId: responseData.userId,
                    companyIds: responseData.companyIds,
                    expiresAt: responseData.expiresAt,
                    role: responseData.role,
                };
                console.log("USER", user)
                localStorage.setItem("user", JSON.stringify(user));
                setCurrentUser(user);
                navigate("/calculation");
            } else {
                console.log(
                    "Login was succesvol, maar de verloopdatum van de token is niet meegeleverd."
                );
            }
        } catch (error) {
            console.error("Login failed", error);
        }
    };

    const refreshToken = useCallback(async () => {
        try {
            const response = await axios.post(`${apiUrl}/auth/refresh-token`, {}, { withCredentials: true });
            if (response.status === 200) {
                const newExpiresAt = response.data.data.expiresAt;
                const updatedUser = { ...currentUser, expiresAt: newExpiresAt };

                localStorage.setItem("user", JSON.stringify(updatedUser));
                console.log("LOCAL USER UPDATED!")
                setCurrentUser(updatedUser);
                return true;
            }
        } catch (error) {
            console.error("Failed to refresh token", error);
            await logout(); // Log out the user if the token cannot be refreshed
            return false;
        }
    }, []);

    const value = { currentUser, login, logout, refreshToken, setCurrentUser };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
