const ToggleButton = ({
                        label,
                        isSelected,
                        onClick,
                        value,
                        id
                      }) => {
  return (
    <button
      className={isSelected ? "btn-yes selected-btn" : "btn-yes"}
      onClick={() => onClick(value, id)}
    >
      {label}
    </button>
  );
};

export default ToggleButton;
