import { useCallback } from "react";

const useFieldUpdater = (handleChange) => {
  const handleFieldUpdate = useCallback(
    (field, value) => {
      console.log("VALUE UPDATE GIFTS", field, value)
      console.log("FIELD", field)
      console.log("Value", value)
      handleChange(field, value);
    },
    [handleChange]
  );

  return { handleFieldUpdate };
};

export default useFieldUpdater;
