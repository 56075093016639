import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.min.css";
import "./style/data-grid.css";
import { useEffect, useState } from "react";

function DataGridComponent({ rowData, colDefs, noDataText = "Geen data gevonden", loadingText = "Laden..." }) {
    const [data, setRowData] = useState(rowData);
    const [cols, setCols] = useState(colDefs);
    const [noData, setNoDataText] = useState(noDataText);
    const [loading, setLoadingText] = useState(loadingText);

    useEffect(() => {
        setRowData(rowData);
        setCols(colDefs);
        setNoDataText(noDataText);
        setLoadingText(loadingText);
    }, [rowData, colDefs]);

    return (
      <div className="ag-theme-quartz custom-ag-grid">
          <AgGridReact
            rowData={data}
            columnDefs={cols}
            domLayout="autoHeight"
            overlayNoRowsTemplate={`<h3>${noData}</h3>`}
            overlayLoadingTemplate={`<h3>${loading}</h3>`}
            defaultColDef={{
                sortable: true,
                resizable: false,
                flex: 1,
                minWidth: 150,
            }}
            rowHeight={50}
            headerHeight={50}
          />
      </div>
    );
}

export default DataGridComponent;
