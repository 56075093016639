import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Header from "./components/header-component/header.component";
import ConfigOverview from "./components/config-overview/config-overview.component";
import ConfigDetail from "./components/config-detail/config-detail.component";
import NetIncomeOverview from "./components/net-income-overview/net-income-overview.component";
import NetIncomeWizard from "./components/net-income-wizard/net-income-wizard.component";
import ProtectedRoute from "./components/protected-route/protected-route.component";
import Login from "./components/login-form/login-form.component";
import Register from "./components/register-form/register-form.component";
import FinanceOverview from "./components/finance-overview/finance-overview.component";
import FinanceWizard from "./components/finance-wizard/finance-wizard.component";
import CustomerDetail from "./components/customer-detail/customer-detail.component";
import CustomerOverview from "./components/customer-overview/customer-overview.component";

import { useAuth } from "./context/authContext";

import "./App.css";

const App = () => {
  const { currentUser, setCurrentUser, refreshToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const storedUser = localStorage.getItem("user");
                if (storedUser) {
                    const parsedUser = JSON.parse(storedUser);
                    setCurrentUser(parsedUser);

                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/auth-check`, { credentials: "include" });
                    const data = await response.json();

                    if (!data.data.loggedIn) {
                        console.log("Token is verlopen of ongeldig.");
                        setCurrentUser(null);
                        localStorage.removeItem("user");
                        window.location.href = "/login";
                    } else if (new Date(parsedUser.expiresAt) <= new Date()) {
                        const refreshed = await refreshToken();
                        if (!refreshed) {
                            setCurrentUser(null);
                            localStorage.removeItem("user");
                            navigate("/login");
                        }
                    }
                } else {
                    console.log("Geen opgeslagen gebruiker gevonden. Navigeren naar login.");
                    setCurrentUser(null);
                    navigate("/login");
                }
            } catch (error) {
                console.error("Failed to check auth status:", error);
                setCurrentUser(null);
                localStorage.removeItem("user");
                navigate("/login");
            } finally {
                setLoading(false);
            }
        };

        checkAuthStatus();
    }, [setCurrentUser, refreshToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app-container">
      <Header user={currentUser} />
      <div className="content-container">
        <Routes>
          <Route path="/login" element={<Login user={currentUser} />} />
          <Route path="/register" element={<Register user={currentUser} />} />
          <Route
            path="/calculation"
            element={
              <ProtectedRoute>
                <NetIncomeOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculation/:companyId/:incomeResultId"
            element={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing"
            component={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/approach"
            component={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/about"
            component={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/configurations"
            element={
              <ProtectedRoute>
                <ConfigOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/config/:year"
            element={
              <ProtectedRoute>
                <ConfigDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/finance"
            element={
              <ProtectedRoute>
                <FinanceOverview />
              </ProtectedRoute>
            }
          />
            <Route
                path="/finance/:userId"
                element={
                    <ProtectedRoute>
                        <FinanceWizard />
                    </ProtectedRoute>
                }
            />
          <Route
            path="/finance/:userId/:financeId"
            element={
              <ProtectedRoute>
                <FinanceWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/advisor/customers"
            element={
                <ProtectedRoute>
                    <CustomerOverview />
                </ProtectedRoute>
            }
          />
            <Route
                path="/advisor/customers/:customerId"
                element={
                    <ProtectedRoute>
                        <CustomerDetail />
                    </ProtectedRoute>
                }
            />
        </Routes>
      </div>
    </div>
  );
};

export default App;
