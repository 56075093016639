import React from "react";
import { useState, useEffect } from "react";
import "./style/customer-overview.style.css";
import { ReactComponent as Detail } from "../../assets/svg/details-icon.svg";
import DataGridComponent from "../data-grid-component/data-grid.component";
import FormatDate from "../../utils/dateConversion";
import {fetchCustomers} from "../../utils/apiHelpers";
import LinkButtonComponent from "../link-button-component/link-button.component";
import {Link} from "react-router-dom";

function CustomerOverview() {
    const [loading, setLoading] = useState(true);
    const url = process.env.REACT_APP_API_BASE_URL;
    const [customers, setCustomers] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [statusCounts, setStatusCounts] = useState({});

    const statusMapping = {
        "fully_filled": { color: "green", label: "Volledig ingevuld" },
        "partially_filled": { color: "yellow", label: "Deels ingevuld" },
        "not_filled": { color: "red", label: "Niet ingevuld" },
        "invited": { color: "gray", label: "Uitgenodigd" }
    };

    const getStatusIcon = (status) => (
      <span className="status-icon" style={{ backgroundColor: statusMapping[status]?.color || "gray" }} />
    );

    const DetailButton = ({ rowData }) => {
        console.log("rowdata", rowData);
        return (
          <Link to={`/advisor/customers/${rowData.action}`}>
              <span className="details-icon">
                  <Detail/>
              </span>
          </Link>
        );
    };


    const [colDefs, setColDefs] = useState([
        {
            field: "status",
            headerName: "Status",
            cellRenderer: (p) => (
              <span className="status-icon" style={{ backgroundColor: statusMapping[p.value]?.color || "gray" }}></span>
            ),
            width: 80
        },
        { field: "name", flex: 1, headerName: "Naam" },
        { field: "email", flex: 1, headerName: "Email" },
        { field: "birthDate", flex: 1, headerName: "Geb. datum" },
        {
            field: "action",
            headerName: "Details",
            cellRenderer: (p) => <DetailButton rowData={p.data} />,
            width: 100
        }
    ]);


    const loadCustomers = async () => {
        try {
            const resultsData = await fetchCustomers(url);
            setCustomers(resultsData);
            await generateDataTableData(resultsData);
        } catch (error) {
            console.error("Error fetching customers:", error);
        } finally {
            setLoading(false);
        }
    };

    const generateDataTableData = (customers) => {
        let statusCounter = { fully_filled: 0, partially_filled: 0, not_filled: 0, invited: 0 };

        const tableData = customers.map((customer) => {
            const status = customer.status || "invited";
            statusCounter[status] = (statusCounter[status] || 0) + 1;

            return {
                status: status,
                name: `${customer.firstName} ${customer.lastName}`,
                email: customer.email,
                birthDate: FormatDate(customer.birthDate),
                action: customer._id
            };
        });

        setStatusCounts(statusCounter);
        setCustomerData(tableData);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredData = customerData.filter((customer) => {
        return customer.name.toLowerCase().includes(searchQuery) || customer.email.toLowerCase().includes(searchQuery);
    });

    useEffect(() => {
        loadCustomers();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    console.log("StatusCounts", statusCounts)

    return (
        <div className="customer-overview">
            <div className="main-container">
                <div className="customer-grid-container">
                    <DataGridComponent rowData={filteredData} colDefs={colDefs} />
                </div>
                <div className="filter-panel">
                    <div className="filter-search">
                        <input type="text" placeholder="Zoek naar klant" onChange={handleSearch} />
                    </div>
                    <div className="filter-buttons-container">
                        <h3>Filter overzicht</h3>
                        <div className="filter-buttons">
                            <button className="filter-btn">Maand</button>
                            <button className="filter-btn">Jaar</button>
                        </div>
                    </div>
                    <div className="status-filter">
                        <h4>Status</h4>
                        <div className="status-list">
                            {Object.keys(statusMapping).map((key) => (
                                <div key={key} className="status-item">
                                    <div className="status-label">
                                        <span className="status-icon" style={{ backgroundColor: statusMapping[key].color }}></span>
                                        <span>{statusMapping[key].label}</span>
                                    </div>
                                    <span className="status-count">{statusCounts[key] || 0}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="reminder-container">
                        <h3>Reminder</h3>
                        <button>
                            Reminder versturen
                        </button>
                    </div>
                    <div className="license-container">
                        <h3>Licenties</h3>
                        <p>8 van de 100</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustomerOverview;
