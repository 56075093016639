import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceHousingStep({ handleChange, incomeResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="finance-content-container">
      <h2>Stap 1</h2>
      <h1>Wat voor een type woning heeft u?</h1>
      <div className="buttons">
        <select>
          <option value="apartment">Appartement</option>
          <option value="house">Tussenwoning</option>
          <option value="corner-house">Hoekwoning</option>
          <option value="detached-house">Vrijstaand</option>
        </select>
      </div>
      <div>
        <h1>Wat is het bouwjaar van uw woning?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.housingCosts.constructionYear}
            onChange={(e) =>
              handleFieldUpdate(
                "housingCosts.constructionYear",
                parseInt(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceHousingStep;
