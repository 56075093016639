import axios from 'axios';

export const fetchData = async (url, options = {}) => {
    try{
        const response = await axios.get(url, {
            withCredentials: true,
            ...options,
        });
        return response.data.data;
    } catch (error) {
        handleApiError(error)
        throw error;
    }
}

export const handleApiError = (error) => {
    if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
    } else if (error.request) {
        console.error("No response received:", error.request);
    } else {
        console.error("Request setup error:", error.message);
    }
};

export const fetchCustomers = async (url) => {
    try {
        return await fetchData(url + "/advisor/customers/");
    } catch (error) {
        // Handle any additional logic if needed
        throw error;
    }
};

export const fetchCustomer = async (url, customerId) => {
    try {
        return await fetchData(url + "/advisor/customers/" + customerId);
    } catch (error) {
        // Handle any additional logic if needed
        throw error;
    }
};

export const fetchConfig = async (url, year) => {
    try {
        const resultsData = await fetchData(url + "/configuration/income-tax/" + year);
        return resultsData.config;
    } catch (error) {
        throw error;
    }
}

export const fetchConfigs = async (url) => {
    try {
        const resultsData = await fetchData(url + "/configuration");
        return resultsData.configs;
    } catch (error) {
        throw error;
    }
}

export const fetchFinanceDataForUser = async (url, userId) => {
    try {
        const resultsData = await fetchData(url + "/finance/" + userId);
        return resultsData.results;
    } catch (error) {
        throw error;
    }
}

export const fetchSingleFinanceDataForUser = async (url, userId, financeId) => {
    try {
        const resultsData = await fetchData(url + "/finance/" + userId + "/" + financeId);
        return resultsData.result;
    } catch (error) {
        throw error;
    }
}

export const fetchIncomeResultDataForYear = async (url, userId, year) => {
    try {
        const resultsData = await fetchData(url + "/incomeresults/" + userId + "/year/" + year);
        return resultsData.result;
    } catch (error) {
        throw error;
    }
}

export const fetchSingleIncomeResultData = async (url, userId, incomeResultId) => {
    try {
        const resultsData = await fetchData(url + "/incomeresults/" + userId + "/" + incomeResultId);
        return resultsData.result;
    } catch (error) {
        throw error;
    }
}

export const fetchIncomeResultData = async (url, userId) => {
    try {
        const resultsData = await fetchData(url + "/incomeresults/" + userId);
        return resultsData.results;
    } catch (error) {
        throw error;
    }
}

export const fetchIncomeWizardProgress = async (url, userId, incomeResultId) => {
    try {
        const resultsData = await fetchData(url + "/incomeresults/" + userId + "/" + incomeResultId + "/progress");
        return resultsData;
    } catch (error) {
        throw error;
    }
}

export const fetchCompanyData = async (url, companyId) => {
    try {
        const resultsData = await fetchData(url + "/companies/" + companyId);
        return resultsData.company;
    } catch (error) {
        throw error;
    }
}