import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import "./style/register-form.style.css";

const Register = ({ user }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useAuth();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        console.log(user);
        if (user !== null) {
            navigate("/calculation");
        }
    }, [user, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                apiUrl + "/auth/register",
                {
                    email,
                    password,
                },
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                login(email, password);
                console.log("Registratie succesvol!");
                navigate("/calculation");
            }
        } catch (error) {
            console.error("Registratie mislukt", error);
            alert("Registratie mislukt");
        }
    };

    return (
        <div className="registration-container">
            <form onSubmit={handleSubmit} className="registration-form">
                <h2 className="registration-title">Registreren</h2>
                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        placeholder="klant@be-easy.nl"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        id="password"
                        placeholder="***********"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="registration-btn">
                    Registreren
                </button>
            </form>
        </div>
    );
};

export default Register;
