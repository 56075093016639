import { ReactComponent as PlusIcon } from "../../../assets/svg/transparent-plus.svg";
import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import {React, useState} from "react";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";
import InputButtonSet from "../../input-button-set/input-button-set.component";
import InputField from "../../input-field/input-field.component";
import axios from "axios";
import {useAuth} from "../../../context/authContext";

export default function FiscalPartnerStep({
  handleChange,
  incomeResult,
  incomeTaxConfig,
}) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
    handleFieldUpdate,
    incomeResult
  );
  const url = process.env.REACT_APP_API_BASE_URL;
  const {currentUser} = useAuth();
  const [selectedButton, setSelectedButton] = useState(incomeResult.fiscalPartner);
  const [email, setEmail] = useState('');

  const handleButtonClick = (value, id, updateType) => {
    setSelectedButton(id);
    if (updateType === "deduction") {
      handleDeductionFieldUpdate(id, value)
    }
    if (updateType === "field") {
      handleFieldUpdate(id, value)
    }
  }

  const handleInviteButtonClick = async () => {
    if (!email) {
      alert("Voer een geldig e-mailadres in");
      return;
    }

    try {
      const response = await axios.post(url + '/invitations', {
        email: email,
        role: 'fiscalpartner',
        userId: currentUser._id,
      },
      {
        withCredentials: true,
      }
    );

      if (response.status === 200 || response.status === 201) {
        alert('Uitnodiging verstuurd!');
        setEmail(''); // Reset het invoerveld
      } else {
        alert('Er is iets misgegaan. Probeer opnieuw.');
      }
    } catch (error) {
      console.error('Fout bij het versturen:', error);
      alert('Er is een fout opgetreden: ' + (error.response?.data?.message || 'Netwerkfout.'));
    }
  };

  return (
    <div className="income-tax-content-container">
      <h2>Stap 1</h2>
      <InputButtonSet
        header="Heeft u een fiscaal partner?"
        isActive={selectedButton === "fiscalPartner"}
        options={[
          { label: "Ja", value: true },
          { label: "Nee", value: false }
        ]}
        updateType="field"
        selectedValue={incomeResult.fiscalPartner}
        handleClick={handleButtonClick}
        fieldId="fiscalPartner"
      />
      {!incomeResult.fiscalPartner && (
        <div>
          <InputField
            header="Deel dit met uw fiscaal partner voor het invullen van uw gegevens"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            buttonText="Delen"
            buttonOnClick={handleInviteButtonClick}
            infoIcon='false'
            inputHeaderClass="input-header-small-text"
            inputContainerClass="input-container-fiscal-partner"
          />
        </div>
      )}
      <div className="income-tax-box-icon">
        <PlusIcon/>
      </div>
    </div>
  );
}
