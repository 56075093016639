import "../style/net-income-wizard.style.css";
import "react-datepicker/dist/react-datepicker.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import {useState} from "react";
import InputField from "../../input-field/input-field.component";
import InputButtonSet from "../../input-button-set/input-button-set.component";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";
import useHandleInputButtonClickHook from "../../../hooks/useHandleInputButtonClick.hook";

function CompanyRevenueStep({ handleChange, incomeResult, incomeTaxConfig }) {
  const months = Object.keys(incomeResult.differentiatedRevenue);
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
    handleFieldUpdate,
    incomeResult
  );

  const [selectedButton, setSelectedButton] = useState(incomeResult.deductions.gifts);
  const validatePositiveNumber = (value) => value > 0 ? "" : "Waarde moet hoger dan 0 zijn";

  const handleRevenueChange = (value) => {
    const monthlyRevenue = value;
    let yearlyRevenue = 0;
    if (incomeResult.revenueDifferentiatesMonthly) {
      months.forEach((month) => {
        yearlyRevenue += month;
      });
    } else {
      yearlyRevenue = monthlyRevenue * 12;
    }
    handleFieldUpdate("grossAverageMonthlyRevenue", parseFloat(monthlyRevenue));
    handleFieldUpdate("grossExpectedRevenue", parseFloat(yearlyRevenue));
    console.log("yearly", yearlyRevenue);
    console.log("monthly", monthlyRevenue);
  };

  const handleCostChange = (value) => {
    const monthlyCosts = value;
    const yearlyCosts = monthlyCosts * 12;
    handleFieldUpdate("grossAverageMonthlyCosts", parseFloat(monthlyCosts));
    handleFieldUpdate("grossExpectedCosts", parseFloat(yearlyCosts));
    console.log("yearlycosts", yearlyCosts);
    console.log("monthlycosts", monthlyCosts);
  };

  const handleSetMonth = (month, value) => {
    handleFieldUpdate("differentiatedRevenue", {
      ...incomeResult.differentiatedRevenue,
      [month]: value,
    });
  };

  const handleSetAllMonths = () => {
    const updatedDifferentiatedRevenue = {};
    months.forEach((month) => {
      updatedDifferentiatedRevenue[month] =
        parseFloat(incomeResult.grossAverageMonthlyRevenue / 12).toFixed(2);
    });
    handleFieldUpdate("differentiatedRevenue", updatedDifferentiatedRevenue);
  };

  const { handleButtonClick } = useHandleInputButtonClickHook(
    handleFieldUpdate,
    handleDeductionFieldUpdate
  );

  console.log(incomeResult.grossAverageMonthlyRevenue);

  return (
    <div className="income-tax-content-container">
      <h2>Stap 4</h2>
      <InputField
        label="Bedrag"
        header="Wat is uw verwachte omzet per maand?"
        type="number"
        value={incomeResult.grossAverageMonthlyRevenue}
        onChange={(e) => handleRevenueChange(parseFloat(e.target.value))}
        validate={validatePositiveNumber}
      />
      <InputField
        label="Bedrag"
        header="Wat zijn uw verwachte kosten per maand?"
        type="number"
        value={incomeResult.grossAverageMonthlyCosts}
        onChange={(e) => handleCostChange(parseFloat(e.target.value))}
        validate={validatePositiveNumber}
      />
      <InputButtonSet
        header="Wijkt uw omzet maandelijks af?"
        isActive={selectedButton === "revenueDifferentiatesMonthly"}
        options={[
          { label: "Ja", value: true },
          { label: "Nee", value: false }
        ]}
        updateType="field"
        selectedValue={incomeResult.revenueDifferentiatesMonthly}
        handleClick={(value, id) => {
          setSelectedButton(id);
          handleButtonClick(value, id, "field", [""])
        }
        }
        fieldId="revenueDifferentiatesMonthly"
      />
        {incomeResult.revenueDifferentiatesMonthly && (
            <div className="month-selection input-container">
              <div className="month-column">
                {months.slice(0, 6).map((month) => (
                    <div className="input-container" key={month}>
                      <label>{month.substring(0, 3)}</label>
                      <input
                          type="number"
                          placeholder={incomeResult.differentiatedRevenue[month]}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            handleSetMonth(month, newValue);
                          }}
                      />
                    </div>
                ))}
              </div>
              <div className="month-column">
                {months.slice(6, 12).map((month) => (
                    <div className="input-container" key={month}>
                      <label>{month.substring(0, 3)}</label>
                      <input
                          type="number"
                          placeholder={incomeResult.differentiatedRevenue[month]}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            handleSetMonth(month, newValue);
                          }}
                      />
                    </div>
                ))}
              </div>
            </div>
        )}
        <InputButtonSet
          header="Heeft u inkomsten uit loondienst?"
          isActive={selectedButton === "employee"}
          options={[
            { label: "Ja", value: true },
            { label: "Nee", value: false }
          ]}
          updateType="field"
          selectedValue={incomeResult.employee}
          handleClick={(value, id) => {
            setSelectedButton(id);
            handleButtonClick(value, id, "field", ["grossIncomeEmployment"])
          }
          }
          fieldId="employee"
        />
        {incomeResult.employee && (
          <InputField
            label="Bedrag"
            header="Hoeveel inkomsten uit loondienst heeft u jaarlijks?"
            type="number"
            value={incomeResult.grossIncomeEmployment}
            onChange={(e) => handleFieldUpdate("grossIncomeEmployment", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
        )}
    </div>
  );
}

export default CompanyRevenueStep;
