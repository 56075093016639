import React, { useState } from 'react';
import { ReactComponent as InputPointer } from "../../assets/svg/input-pointer.svg";
import "./style/animated-pointer.style.css";


const AnimatedPointer = ({ children, isActive }) => {
    return (
        <div className="input-header">
            {isActive && <InputPointer className="input-pointer show" />}
            {children}
        </div>
    );
};

export default AnimatedPointer;
