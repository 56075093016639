import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopNavigation from "../top-navigation/top-navigation.component";
import PageHeader from "../page-header/page-header.component";

import "./style/header.css";

const Header = ({ user }) => {
  return (
    <div className="header-container">
      <TopNavigation user={user} />
      <PageHeader />
    </div>
  );
};

export default Header;
