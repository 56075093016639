import { React, useState } from "react";
import "./style/finance-navigation.css";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/transparent-plus.svg";
import { ReactComponent as DivideIcon } from "../../../assets/svg/transparent-divide.svg";
import { ReactComponent as MultiplyIcon } from "../../../assets/svg/transparent-multiply.svg";

const FinanceNavigation = ({
  categories,
  currentFinanceStep,
  handleChange,
}) => {
  const [expandedStates, setExpandedStates] = useState({
    box_1: false,
    box_2: false,
    box_3: false,
  });

  const toggleBox = (boxName) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [boxName]: !prevStates[boxName],
    }));
  };

  function isBoxExpanded(boxKey, steps, currentFinanceStep) {
    const isActive = Object.values(steps).some(
      (step) => step.order === currentFinanceStep
    );
    return expandedStates[boxKey] || isActive;
  }

  function getIndicatorClass(stepValue, currentFinanceStep) {
    if (currentFinanceStep === stepValue.order) {
      return "selected active-indicator";
    } else if (stepValue.order < currentFinanceStep) {
      return "selected done-indicator";
    } else {
      return "selected not-started-indicator";
    }
  }

  function getActiveClass(stepValue, currentFinanceStep) {
    if (currentFinanceStep === stepValue.order) {
      return "active";
    } else {
      return "inactive";
    }
  }

  function getBoxClass(steps, currentFinanceStep) {
    const stepOrders = Object.values(steps).map((step) => step.order);
    if (stepOrders.includes(currentFinanceStep)) {
      return "selected started-indicator";
    } else if (stepOrders.every((order) => order < currentFinanceStep)) {
      return "not-selected done-indicator";
    } else {
      return "not-selected not-started-indicator";
    }
  }

  return (
    <div className="finance-navigation-container">
      {Object.entries(categories).map(([boxKey, boxValue]) => (
        <div key={boxKey} className="finance-navigation-box">
          <ul className="finance-navigation-items">
            <li
              key={boxKey}
              className={`finance-navigation-title ${getBoxClass(
                boxValue.steps,
                currentFinanceStep
              )}`}
              onClick={() => toggleBox(boxKey)}
            >
              <h2>{boxValue.title}</h2>
              <h4>{boxValue.subTitle}</h4>
            </li>
          </ul>
          <ul
            className={`finance-navigation-sub-items ${
              isBoxExpanded(boxKey, boxValue.steps, currentFinanceStep)
                ? "expanded"
                : ""
            }`}
          >
            {Object.entries(boxValue.steps).map(([stepKey, stepValue]) => (
              <li
                key={stepKey}
                className={`finance-navigation-sub-title ${getIndicatorClass(
                  stepValue,
                  currentFinanceStep
                )}`}
                onClick={() => handleChange(stepValue.order)}
              >
                <h3
                  className={`${getActiveClass(stepValue, currentFinanceStep)}`}
                >
                  Stap {stepValue.order}
                </h3>
                <h4
                  className={`${getActiveClass(stepValue, currentFinanceStep)}`}
                >
                  {stepValue.title}
                </h4>
              </li>
            ))}
          </ul>
          <div
            className="finance-navigation-collapse"
            onClick={() => toggleBox(boxKey)}
          >
            {expandedStates[boxKey] ? <ArrowUp /> : <ArrowDown />}
          </div>
          <div className="finance-box-icon">
            {boxKey === "box_1" && <PlusIcon />}
            {boxKey === "box_2" && <DivideIcon />}
            {boxKey === "box_3" && <MultiplyIcon />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FinanceNavigation;
