import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import {useState} from "react";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";
import InputButtonSet from "../../input-button-set/input-button-set.component";
import InputField from "../../input-field/input-field.component";
import useHandleInputButtonClickHook from "../../../hooks/useHandleInputButtonClick.hook";

function CapitalStep({ handleChange, incomeResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
    handleFieldUpdate,
    incomeResult
  );

  const calculeTaxFreeCapital = () => {
    let taxFreeCapital = incomeTaxConfig.taxFreeCapitalLimit;

    if (incomeResult.fiscalPartner) {
      taxFreeCapital = taxFreeCapital * 2;
    }

    return taxFreeCapital;
  };

  const [selectedButton, setSelectedButton] = useState(null);
  const validatePositiveNumber = (value) => value >= 0 ? "" : "Waarde moet hoger dan 0 zijn";

  const { handleButtonClick } = useHandleInputButtonClickHook(
    handleFieldUpdate,
    handleDeductionFieldUpdate
  );

  return (
    <div className="income-tax-content-container">
      <h2>Stap 13</h2>
      <InputButtonSet
        header={`Heeft u een eigen vermogen van meer dan €${calculeTaxFreeCapital()}?`}
        isActive={selectedButton === "capitalOverTaxFreeLimit"}
        options={[
          { label: "Ja", value: true },
          { label: "Nee", value: false }
        ]}
        updateType="field"
        selectedValue={incomeResult.capitalOverTaxFreeLimit}
        handleClick={(value, id) => {
          setSelectedButton(id);
          handleButtonClick(value, id, "field", ["capitalSavings", "capitalInvestments", "capitalDebts", "capitalTaxFiscalPartnerPercentage"])
        }
        }
        fieldId="capitalOverTaxFreeLimit"
      />
      {incomeResult.capitalOverTaxFreeLimit && (
        <div>
          <InputField
            label="Bedrag"
            header="Hoeveel spaargeld heeft u?"
            type="number"
            value={incomeResult.capitalSavings}
            onChange={(e) => handleFieldUpdate("capitalSavings", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
          <InputField
            label="Bedrag"
            header="Hoeveel geld heeft u geïnvesteerd (denk aan beleggingen)?"
            type="number"
            value={incomeResult.capitalInvestments}
            onChange={(e) => handleFieldUpdate("capitalInvestments", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
          <InputField
            label="Bedrag"
            header="Hoeveel schulden heeft u (denk aan persoonlijke leningen)?"
            type="number"
            value={incomeResult.capitalDebts}
            onChange={(e) => handleFieldUpdate("capitalDebts", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
          <InputField
            label="Bedrag"
            header="Welk percentage van dit kapitaal wilt u bij uw fiscaal partner aangeven?"
            type="number"
            value={incomeResult.capitalTaxFiscalPartnerPercentage}
            onChange={(e) => handleFieldUpdate("capitalTaxFiscalPartnerPercentage", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
        </div>
      )}
    </div>
  );
}

export default CapitalStep;
