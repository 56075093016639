import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceLeisureCostsStep({
  handleChange,
  incomeResult,
  financeResult,
}) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="finance-content-container">
      <h2>Stap 13</h2>
      <div>
        <h1>Hoeveel betaalt u aan vakanties?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.leisureCosts.vacationCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "leisureCosts.vacationCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan hobbies?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.leisureCosts.hobbyCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "leisureCosts.hobbyCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan overige vrijetijdsuitgaven?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.leisureCosts.miscLeisureCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "leisureCosts.miscLeisureCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceLeisureCostsStep;
