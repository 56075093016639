import React, { createContext, useState, useContext } from "react";

const FocusContext = createContext();

export const FocusProvider = ({ children }) => {
  const [isFocused, setIsFocused] = useState(null);

  const handleFocus = (id) => {
    setIsFocused(id);
  };

  const handleBlur = () => {
    setIsFocused(null);
  };

  return (
    <FocusContext.Provider value={{ isFocused, handleFocus, handleBlur }}>
      {children}
    </FocusContext.Provider>
  );
};

export const useFocus = () => useContext(FocusContext);
