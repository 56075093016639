// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finance-description-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.5rem;
  position: relative;
}

.finance-description-container h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-align: left;
}

.finance-description-container p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: left;
}

.finance-description-container button {
  background: var(--Blue, #3a489d);
  border: 0;
  color: var(--White, #fff);
}

.finance-description-icon {
  position: absolute;
  opacity: 0.04;
  display: flex;
  flex-direction: column;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/finance-wizard/finance-description/style/finance-description.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":[".finance-description-container {\n  display: flex;\n  flex-direction: column;\n  padding: 2.5rem 1.5rem;\n  position: relative;\n}\n\n.finance-description-container h1 {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 1.25rem;\n  letter-spacing: 0;\n  text-align: left;\n}\n\n.finance-description-container p {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 1rem;\n  letter-spacing: 0;\n  text-align: left;\n}\n\n.finance-description-container button {\n  background: var(--Blue, #3a489d);\n  border: 0;\n  color: var(--White, #fff);\n}\n\n.finance-description-icon {\n  position: absolute;\n  opacity: 0.04;\n  display: flex;\n  flex-direction: column;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
