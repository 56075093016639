import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceTransportStep({ handleChange, incomeResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 8</h2>
      <div>
        <h1>Hoeveel betaalt u aan afbetaling, afschrijving transport?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.transportCosts.debtPaymentCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "transportCosts.debtPaymentCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan motorrijtuigenbelasting?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.transportCosts.motorVehicleTaxCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "transportCosts.motorVehicleTaxCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan onderhoud?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.transportCosts.transportMaintenanceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "transportCosts.transportMaintenanceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan verzekeringen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.transportCosts.transportInsuranceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "transportCosts.transportInsuranceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan brandstof?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.transportCosts.transportFuelCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "transportCosts.transportFuelCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan openbaar vervoer?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.transportCosts.transportPublicTransportCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "transportCosts.transportPublicTransportCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceTransportStep;
