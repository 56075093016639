import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceCarStep({ handleChange, incomeResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  console.log("CARCOSTS", financeResult.carCosts.mileage)
  return (
    //TODO - Loopje toevoegen voor meerdere auto's
    <div className="finance-content-container">
      <h2>Stap 2</h2>
      <h1>Wat voor soort auto heeft u?</h1>
      <div className="select">
        <select>
          <option value="small">Klein</option>
          <option value="compact">Compact</option>
          <option value="compact-middle">Compact middenklasse</option>
          <option value="middle">Middenklasse</option>
        </select>
      </div>
      <h1>Heeft u de auto nieuw gekocht of tweedehands?</h1>
      <div className="buttons">
        <button
          className={
            financeResult.carCosts.carCondition === "new"
              ? "btn selected-btn"
              : "btn"
          }
          onClick={() => handleFieldUpdate("capitalOverTaxFreeLimit", "nieuw")}
        >
          Nieuw
        </button>
        <button
          className={
            financeResult.carCosts.carCondition === "tweedehands"
              ? "btn selected-btn"
              : "btn"
          }
          onClick={() =>
            handleFieldUpdate("carCosts.carCondition", "tweedehands")
          }
        >
          Tweedehands
        </button>
      </div>
      <div>
        <h1>Hoeveel kilometer rijd u per jaar?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.carCosts.mileage}
            onChange={(e) =>
              handleFieldUpdate("carCosts.mileage", parseFloat(e.target.value))
            }
          />
        </div>
      </div>
      <div className="buttons">
        <button className="btn">Nog een auto toevoegen</button>
      </div>
    </div>
  );
}

export default FinanceCarStep;
