// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --Default-Font: Montserrat;
    --Default-Font-Size: 14px;

    --Green:#47b37c;
    --Green-Medium:#9ED4B9;
    --Green-Light:#F6FBF8;

    --Dark: #101a42;
    --Dark-Medium:#888DA1;
    --Dark-Light:#F3F3F5;

    --Red: #F05056;
    --Red-Medium:#F8A8AB;
    --Red-Light:#FEF6F6;

    --Yellow: #FFCE4B;
    --Yellow-Medium:#FFE7A5;
    --Yellow-Light:#FFFAED;

    --Info: #3377BC;
    --Info-Medium:#99BBDE;
    --Info-Light:#F4F8FB;

    --Blue: #3B489E;
    --Blue-Medium: #9DA4CF;
    --Blue-Light: #F5F6FA;

    --Grey-Medium: #A8A6B6;

    --White: #fff;
    --Black: #000;

    --Default-Btn-Radius:45px;
    --Default-Border-Radius: 1rem;

    --App-Width: 1196px;
}`, "",{"version":3,"sources":["webpack://./src/vars.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,yBAAyB;;IAEzB,eAAe;IACf,sBAAsB;IACtB,qBAAqB;;IAErB,eAAe;IACf,qBAAqB;IACrB,oBAAoB;;IAEpB,cAAc;IACd,oBAAoB;IACpB,mBAAmB;;IAEnB,iBAAiB;IACjB,uBAAuB;IACvB,sBAAsB;;IAEtB,eAAe;IACf,qBAAqB;IACrB,oBAAoB;;IAEpB,eAAe;IACf,sBAAsB;IACtB,qBAAqB;;IAErB,sBAAsB;;IAEtB,aAAa;IACb,aAAa;;IAEb,yBAAyB;IACzB,6BAA6B;;IAE7B,mBAAmB;AACvB","sourcesContent":[":root {\n    --Default-Font: Montserrat;\n    --Default-Font-Size: 14px;\n\n    --Green:#47b37c;\n    --Green-Medium:#9ED4B9;\n    --Green-Light:#F6FBF8;\n\n    --Dark: #101a42;\n    --Dark-Medium:#888DA1;\n    --Dark-Light:#F3F3F5;\n\n    --Red: #F05056;\n    --Red-Medium:#F8A8AB;\n    --Red-Light:#FEF6F6;\n\n    --Yellow: #FFCE4B;\n    --Yellow-Medium:#FFE7A5;\n    --Yellow-Light:#FFFAED;\n\n    --Info: #3377BC;\n    --Info-Medium:#99BBDE;\n    --Info-Light:#F4F8FB;\n\n    --Blue: #3B489E;\n    --Blue-Medium: #9DA4CF;\n    --Blue-Light: #F5F6FA;\n\n    --Grey-Medium: #A8A6B6;\n\n    --White: #fff;\n    --Black: #000;\n\n    --Default-Btn-Radius:45px;\n    --Default-Border-Radius: 1rem;\n\n    --App-Width: 1196px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
