import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import { useAuth } from "../../../context/authContext";

import "../style/net-income-wizard.style.css";
import "./style/income-result.style.css";

function IncomeResult({incomeResult}) {
  const {currentUser} = useAuth();
  const [incomeResultData, setIncomeResultData] = useState(null);

  const fetchIncomeResultData = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/incomeresults/${currentUser.userId}/${incomeResult._id}/calculateTax`;

      const response = await axios.post(url,
        {

        },
        {
          withCredentials: true
        }
      );
      console.log("response", response.data.data);
      setIncomeResultData(response.data.data);
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  }, [currentUser.userId, incomeResult._id]);


  useEffect(() => {
      if (currentUser?.userId && incomeResult?._id) {
        fetchIncomeResultData();
      }
    },
    [currentUser.userId, incomeResult]);

  useEffect(() => {
    if (incomeResultData) {
      console.log("Updated IncomeResultData:", incomeResultData);
    }
  }, [incomeResultData]);

  if (!incomeResultData || Object.values(incomeResultData).every(value => value === 0)) {
    return <p>Geen resultaten beschikbaar. Controleer uw invoer.</p>;
  }

  const monthlyIncomeTax = incomeResultData.incomeTaxAfterDiscounts/12
  const monthlyHealthCareLaw = (incomeResultData.healthcareLaw/12)
  const totalSum =
    Math.abs(monthlyIncomeTax) +
    Math.abs(monthlyHealthCareLaw) +
    Math.abs(incomeResultData.monthlySpendableIncome);

  const incomeTaxProportion = (Math.abs(monthlyIncomeTax) / totalSum) * 100;
  const healthcareLawProportion =
    (Math.abs(monthlyHealthCareLaw) / totalSum) * 100;
  const spendableIncomeProportion =
    (Math.abs(incomeResultData.monthlySpendableIncome) / totalSum) * 100;
  console.log("incomeResult", incomeResult)
  return (
    <div className="income-tax-content-container">
      <h1 className="center">Alles is correct ingevuld!</h1>
      <div className="income-tax-overview-container">
        <div className="overview-text">
          <p>
            U heeft de complete wizard doorlopen en hier onder zie je wat er
            be-rekend is!
          </p>
          <p>
            Mocht u meer detail informatie willen het desbetreffende onderwerp
            uitklappen.
          </p>
          <p>
            En nog een verwijzing naar het dashboard waar ze hun financien bij
            kunnen houden.
          </p>
        </div>
        <div className="overview-results">
          <div className="result-item">
            <span className="indicator green-indicator"></span>
            <span className="amount">
              {parseFloat(incomeResultData.monthlySpendableIncome).toFixed(2)}
            </span>
            <span className="description">Besteedbaar inkomen</span>
          </div>
          <div className="result-item">
            <span className="indicator red-indicator"></span>
            <span className="amount">
              {parseFloat(incomeResultData.incomeTaxAfterDiscounts / 12).toFixed(2)}
            </span>
            <span className="description">Inkomsten belasting</span>
          </div>
          <div className="result-item">
            <span className="indicator yellow-indicator"></span>
            <span className="amount">
              {parseFloat(incomeResultData.healthcareLaw / 12).toFixed(2)}
            </span>
            <span className="description">Bijdrage zorgverzekeringswet</span>
          </div>
        </div>
      </div>
      <div className="income-distribution-bar">
        <div className="bar">
          <div
            className="segment green-segment"
            style={{ width: `${spendableIncomeProportion}%` }}
          >
            <span className="label">
              {spendableIncomeProportion.toFixed(1)}%
            </span>
          </div>
          <div
            className="segment red-segment"
            style={{ width: `${incomeTaxProportion}%` }}
          >
            <span className="label">{incomeTaxProportion.toFixed(1)}%</span>
          </div>
          <div
            className="segment yellow-segment"
            style={{ width: `${healthcareLawProportion}%` }}
          >
            <span className="label">{healthcareLawProportion.toFixed(1)}%</span>
          </div>
        </div>
      </div>
      <div>
        <h1>RESULTAAT - CONTROLE LIJST - WORDT VERWIJDERD NA TEST</h1>
        <ul>
          <li>
            Opbrengsten:
            {parseFloat(incomeResult.grossExpectedRevenue).toFixed(2)}
          </li>
          <li>
            Overige bedrijfskosten:
            {parseFloat(incomeResult.grossExpectedCosts).toFixed(2)}
          </li>
        </ul>
        <h1>BELASTBAAR INKOMEN - CONTROLE LIJST</h1>
        <ul>
          <li>
            Belastbaar inkomen box 1:
            {parseFloat(incomeResultData.taxableIncome).toFixed(2)}
          </li>
          <li>
            Totaal aftrekposten: {parseFloat(incomeResultData.totalDeductions).toFixed(2)}
          </li>
          <li>
            Gecombineerde inkomensheffing:
            {parseFloat(incomeResultData.incomeTax).toFixed(2)}
          </li>
          <li>Verschuldigde belasting en premie: {parseFloat(incomeResultData.incomeTaxAfterDiscounts).toFixed(2)}</li>
          <li>Bijdrage ZVW: {parseFloat(incomeResultData.healthcareLaw).toFixed(2)}</li>
        </ul>
        <p>
          Inkomstenbelasting na aftrekposten:{" "}
          {parseFloat(incomeResultData.incomeTaxAfterDiscounts).toFixed(2)}
        </p>
        <p>
          <b>Vrij besteedbaar inkomen: </b>{" "}
          {parseFloat(incomeResultData.spendableIncome).toFixed(2)}
        </p>
        <p>
          <b>Vrij besteedbaar inkomen per maand: </b>{" "}
          {parseFloat(incomeResultData.monthlySpendableIncome).toFixed(2)}
        </p>
      </div>
    </div>
  );
}

export default IncomeResult;
