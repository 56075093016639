// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header-container {
  text-align: left;
  position: absolute;
  background: var(--White, #fff);
  width: 100%;
  max-width: 1196px;
  height: 2.25rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.5rem 0px rgba(58, 72, 157, 0.08);
  margin: 10.25rem 0 0 0;
  border: 1px solid #ddd;
}

.page-header-container-text {
  color: var(--Green, #47b37c);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/page-header/style/page-header.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,qBAAqB;EACrB,sDAAsD;EACtD,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,+BAA+B;AACjC","sourcesContent":[".page-header-container {\n  text-align: left;\n  position: absolute;\n  background: var(--White, #fff);\n  width: 100%;\n  max-width: 1196px;\n  height: 2.25rem;\n  border-radius: 0.5rem;\n  box-shadow: 0px 0px 0.5rem 0px rgba(58, 72, 157, 0.08);\n  margin: 10.25rem 0 0 0;\n  border: 1px solid #ddd;\n}\n\n.page-header-container-text {\n  color: var(--Green, #47b37c);\n  text-align: left;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n  padding: 0.5rem 0 0.5rem 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
