// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px rgba(58, 72, 157, 0.08);
  background: var(--White, #fff);
  flex-shrink: 0;
  border: 1px solid #ddd;
  justify-content: center;
  padding: 2.62rem 3rem;
}

.login-form {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

input {
  width: 266px;
}

input:first-child {
  margin-bottom: 10px;
}

.login-title {
  text-align: center;
  margin-bottom: 11px;
}

.login-btn {
  width: 100%;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/login-form/style/login-form.style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,mDAAmD;EACnD,8BAA8B;EAC9B,cAAc;EACd,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".login-container {\n  display: flex;\n  flex-direction: row;\n  max-width: 1440px;\n  height: 100%;\n  border-radius: 16px;\n  box-shadow: 0px 0px 8px 0px rgba(58, 72, 157, 0.08);\n  background: var(--White, #fff);\n  flex-shrink: 0;\n  border: 1px solid #ddd;\n  justify-content: center;\n  padding: 2.62rem 3rem;\n}\n\n.login-form {\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n}\n\ninput {\n  width: 266px;\n}\n\ninput:first-child {\n  margin-bottom: 10px;\n}\n\n.login-title {\n  text-align: center;\n  margin-bottom: 11px;\n}\n\n.login-btn {\n  width: 100%;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
