import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";
import {useState} from "react";
import InputButtonSet from "../../input-button-set/input-button-set.component";
import InputField from "../../input-field/input-field.component";
import useHandleInputButtonClickHook from "../../../hooks/useHandleInputButtonClick.hook";

function DisabilityInsuranceStep({ handleChange, incomeResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
      handleFieldUpdate,
      incomeResult
  );

  const [selectedButton, setSelectedButton] = useState(incomeResult.deductions.gifts);

  const validatePositiveNumber = (value) => value >= 0 ? "" : "Waarde moet hoger dan 0 zijn";

  const { handleButtonClick } = useHandleInputButtonClickHook(
    handleFieldUpdate,
    handleDeductionFieldUpdate
  );

  return (
    <div className="income-tax-content-container">
      <h2>Stap 5</h2>
        <InputButtonSet
          header="Heeft u een AOV verzekering?"
          isActive={selectedButton === "disabilityInsurance"}
          options={[
            { label: "Ja", value: true },
            { label: "Nee", value: false }
          ]}
          updateType="deduction"
          selectedValue={incomeResult.deductions.disabilityInsurance}
          handleClick={(value, id) => {
            setSelectedButton(id);
            handleButtonClick(value, id, "deduction", ["paidDisabilityInsurance"])
          }
          }
          fieldId="disabilityInsurance"
        />
      { incomeResult.deductions.disabilityInsurance && (
        <InputField
          label="Bedrag"
          header="Hoeveel heeft u het afgelopen jaar betaald aan premie voor uw AOV?"
          type="number"
          value={incomeResult.deductions.paidDisabilityInsurance}
          onChange={(e) => handleDeductionFieldUpdate({ paidDisabilityInsurance: parseFloat(e.target.value) })}
          validate={validatePositiveNumber}
        />
      )}
    </div>
  );
}

export default DisabilityInsuranceStep;
