import { useCallback } from "react";

const useHandleInputButtonClick = (handleFieldUpdate, handleDeductionFieldUpdate) => {
  const handleButtonClick = useCallback(
    (value, id, updateType, resetFields = []) => {
      if (updateType === "deduction") {
        const updates = { [id]: value };
        console.log("UPDATES", updates)
        if (value === false && resetFields.length > 0) {
          resetFields.forEach((field) => {
            updates[field] = 0;
          });
        }
        handleDeductionFieldUpdate(updates);
      }

      if (updateType === "field") {
        const updates = { [id]: value };
        if (value === false && resetFields.length > 0) {
          resetFields.forEach((field) => {
            updates[field] = 0;
          });
        }

        handleFieldUpdate(id, value);
      }
    },
    [handleFieldUpdate, handleDeductionFieldUpdate]
  );

  return { handleButtonClick };
};

export default useHandleInputButtonClick;
