import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext";
import { fetchIncomeResultData } from "../../utils/apiHelpers";
import LinkButtonComponent from "../link-button-component/link-button.component";
import DataGridComponent from "../data-grid-component/data-grid.component";

function NetIncomeOverview() {
  const [loading, setLoading] = useState(true);
  const [incomeResults, setIncomeResults] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;
  const { currentUser } = useAuth();

  const viewIncomeResultButton = (p) => {
    if (!p.value) return <span>Geen data</span>;

    return <LinkButtonComponent link={`/calculation/${currentUser.userId}/${p.value}`} text={"Inzien"} />;
  };


  const removeIncomeResultButton = p => {
    return <LinkButtonComponent link={`/calculation/${ currentUser.userId }/${p.value}`} text={"Verwijderen"} />
  }

  const [colDefs, setColDefs] = useState([
    { field: "id", flex: 1, headerName: "ID" },
    { field: "year", flex: 1, headerName: "Jaar" },
    { field: "viewAction", flex: 1, headerName: "Inzien", cellRenderer: viewIncomeResultButton},
    { field: "deleteAction", flex: 1, headerName: "Verwijderen", cellRenderer: removeIncomeResultButton}
  ]);
  const [configData, setConfigData] = useState(null);

  const generateDataTableData = async (configs) => {
    let tableData = []

    configs.forEach((incomeResult) => {
      let dataObject = {
        id: incomeResult._id,
        year: incomeResult.year,
        viewAction: incomeResult._id,
        deleteAction: incomeResult._id
      }
      tableData.push(dataObject)
    })
    setConfigData(tableData)
  }

  const loadIncomeResultData = async () => {
    try {
      const resultsData = await fetchIncomeResultData(url, currentUser.userId);
      setIncomeResults(resultsData);
      await generateDataTableData(resultsData);
    } catch (error) {
      console.error("Error tijdens het laden van inkomensresultaten:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadIncomeResultData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="column-full-width center">
      <LinkButtonComponent link={`/calculation/${ currentUser.userId }/new`} text={"Nieuwe berekening"} />
      <DataGridComponent rowData={configData} colDefs={colDefs} />
    </div>
  );
}
export default NetIncomeOverview;
