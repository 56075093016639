import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import {useState} from "react";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";
import InputButtonSet from "../../input-button-set/input-button-set.component";
import InputField from "../../input-field/input-field.component";
import useHandleInputButtonClickHook from "../../../hooks/useHandleInputButtonClick.hook";

function LegalFormStep({ handleChange, incomeResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
    handleFieldUpdate,
    incomeResult
  );

  const [selectedButton, setSelectedButton] = useState("");
  const validatePositiveNumber = (value) => value > 0 ? "" : "Waarde moet hoger dan 0 zijn";

  const { handleButtonClick } = useHandleInputButtonClickHook(
    handleFieldUpdate,
    handleDeductionFieldUpdate
  );

  return (
    <div className="income-tax-content-container">
      <h2>Stap 3</h2>
      <InputButtonSet
        header="Heeft u of uw partner een eenmanszaak of VOF?"
        isActive={selectedButton === "isEntrepeneur"}
        options={[
          { label: "Ja", value: true },
          { label: "Nee", value: false }
        ]}
        updateType="field"
        selectedValue={incomeResult.isEntrepeneur}
        handleClick={(value, id) => {
          setSelectedButton(id);
          handleButtonClick(value, id, "field")
        }
        }
        fieldId="isEntrepeneur"
      />
        {incomeResult.isEntrepeneur && (
          <InputButtonSet
            header="Bent u de eigenaar van deze onderneming?"
            isActive={selectedButton === "mainUserIsEntrepeneur"}
            options={[
              { label: "Ja", value: true },
              { label: "Nee", value: false }
            ]}
            updateType="field"
            selectedValue={incomeResult.mainUserIsEntrepeneur}
            handleClick={(value, id) => {
              setSelectedButton(id);
              handleButtonClick(value, id, "field")
            }
            }
            fieldId="mainUserIsEntrepeneur"
          />
        )}
          {(!incomeResult.mainUserIsEntrepeneur || !incomeResult.isEntrepeneur) && (
            <InputButtonSet
              header="Is uw fiscaal partner de eigenaar van een eenmanszaak of VOF?"
              isActive={selectedButton === "fiscalPartnerIsEntrepeneur"}
              options={[
                { label: "Ja", value: true },
                { label: "Nee", value: false }
              ]}
              updateType="field"
              selectedValue={incomeResult.fiscalPartnerIsEntrepeneur}
              handleClick={(value, id) => {
                setSelectedButton(id);
                handleButtonClick(value, id, "field")
              }
              }
              fieldId="fiscalPartnerIsEntrepeneur"
            />
        )}
        {incomeResult.isEntrepeneur && (
          <InputField
            label="Bedrag"
            header="In welk jaar is deze onderneming opgericht?"
            type="number"
            value={incomeResult.companyYear}
            onChange={(e) => handleFieldUpdate("companyYear", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
        )}
          {(incomeResult.companyYear >= (new Date().getFullYear() - 5) && incomeResult.isEntrepeneur) && (
            <InputButtonSet
              header="Is er vaker dan 3 keer de startersaftrek toegepast?"
              isActive={selectedButton === "starterDeductionEligible"}
              options={[
                { label: "Ja", value: true },
                { label: "Nee", value: false }
              ]}
              updateType="field"
              selectedValue={incomeResult.starterDeductionEligible}
              handleClick={(value, id) => {
                setSelectedButton(id);
                handleButtonClick(value, id, "field")
              }
              }
              fieldId="starterDeductionEligible"
            />
          )}
    </div>
  );
}

export default LegalFormStep;
