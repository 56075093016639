import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeductionsForm from "../deductions-form/deductions-form.component";
import { fetchConfig } from "../../utils/apiHelpers";

function ConfigDetail() {
  const { year } = useParams();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const url = process.env.REACT_APP_API_BASE_URL;

  const initialState = {
    year: new Date().getFullYear(),
    hoursCriterion: 0,
    starterDeduction: 0,
    starterDeductionYears: 0,
    selfEmploymentDeduction: 0,
    smeProfitExemption: 0,
    mortgageInterest: 0,
    notionalRentalValueMin: 0,
    notionalRentalValueMax: 0,
    incomeTaxBrackets: [],
    incomeRelatedCombinationTaxBrackets: [],
    investmentDeductionBrackets: [],
    labourDiscountBrackets: [],
    generalTaxCreditBrackets: [],
    deductableMedicalExpensesBrackets: [],
    healthcareLawPercentage: 0,
  };

  const loadConfig = async () => {
    try {
      const resultsData = await fetchConfig(url, year);
      setConfig(resultsData);
    } catch (error) {
      // Handle any additional logic if needed
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadConfig();
  }, [year]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Configuratie Inkomstenbelasting voor {year}</h1>
      <DeductionsForm incomeTaxConfig={config} setIncomeTaxConfig={setConfig} />
    </div>
  );
}

export default ConfigDetail;
