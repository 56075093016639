import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style/page-header.css";

const PageHeader = () => {
  const [className, setClassName] = useState(
    "page-header-container large-text"
  );
  const location = useLocation();
  let path = "";
  switch(location.pathname){
    case "/":
      path = "Home";
      break;
    case "/login":
      path = "Login";
      break;
    case "/register":
      path = "Register";
      break;
    case "/profile":
      path = "Profile";
      break;
    case "/dashboard":
      path = "Dashboard";
      break;
    case "/create":
      path = "Create";
      break;
    case "/advisor/customers":
      path = "Klantenoverzicht";
      break;
    case "/configurations":
      path = "Configuratieoverzicht";
      break;
    case "/finance":
      path = "Kasboekoverzicht";
      break;
    case "/edit":
      path = "Edit";
      break;
    case "/calculation":
      path = "Berekeningsoverzicht";
      break;
    case "/view":
      path = "View";
      break;
    case "/search":
      path = "Search";
      break;
    case "/about":
      path = "About";
      break;
    case "/contact":
      path = "Contact";
      break;
    default:
      path = "Home";
      break;
  }

  if(location.pathname.includes("login")){
      path = "Login"
  }

  return (
    <header className="page-header-container">
          <h1 className="page-header-container-text">
              {path}
          </h1>
    </header>
  );
};

export default PageHeader;
