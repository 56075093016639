// InputComponent.js
import React from "react";
import {Link} from "react-router-dom";

function LinkButtonComponent({ link, text }) {
    return (
        <button>
            <Link to={`${link}`}>
                {text}
            </Link>
        </button>
    );
}

export default LinkButtonComponent;
