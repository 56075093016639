import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";
import {useState} from "react";
import InputField from "../../input-field/input-field.component";
import InputButtonSet from "../../input-button-set/input-button-set.component";
import useHandleInputButtonClickHook from "../../../hooks/useHandleInputButtonClick.hook";

function HousingStep({ handleChange, incomeResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
    handleFieldUpdate,
    incomeResult
  );
  const [selectedButton, setSelectedButton] = useState(null);
  const validatePositiveNumber = (value) => value >= 0 ? "" : "Waarde moet hoger dan 0 zijn";

  const { handleButtonClick } = useHandleInputButtonClickHook(
    handleFieldUpdate,
    handleDeductionFieldUpdate
  );

  return (
    <div className="income-tax-content-container">
      <h2>Stap 6</h2>
      <InputButtonSet
        header="Heeft u of uw financieel partner een eigen woning?"
        isActive={selectedButton === "homeOwner"}
        options={[
          { label: "Ja", value: true },
          { label: "Nee", value: false }
        ]}
        updateType="field"
        selectedValue={incomeResult.homeOwner}
        handleClick={(value, id) =>
          {
            setSelectedButton(id);
            handleButtonClick(value, id, "field", ["wozValue", "paidMortgageCosts", "paidMortgageInterest"])
          }
        }
        fieldId="homeOwner"
      />
      {incomeResult.homeOwner && (
        <InputButtonSet
          header="Gaat het hier om een koopwoning?"
          isActive={selectedButton === "ownerOccupiedHome"}
          options={[
            { label: "Ja", value: true },
            { label: "Nee", value: false }
          ]}
          updateType="field"
          selectedValue={incomeResult.homeOwner}
          handleClick={(value, id) =>
          {
            setSelectedButton(id);
            handleButtonClick(value, id, "field", ["wozValue", "paidMortgageCosts", "paidMortgageInterest"])
          }
          }
          fieldId="ownerOccupiedHome"
        />
      )}
        {incomeResult.homeOwner && incomeResult.ownerOccupiedHome && (
          <InputField
            label="Bedrag"
            header="Wat was de WOZ-waarde van uw woning per 1 januari 2022?"
            type="number"
            value={incomeResult.wozValue}
            onChange={(e) => handleFieldUpdate("wozValue", parseFloat(e.target.value))}
            validate={validatePositiveNumber}
          />
        )}
        {incomeResult.homeOwner && (
          <InputButtonSet
            header="Heeft u hypotheekkosten gemaakt?"
            isActive={selectedButton === "mortgageCosts"}
            options={[
              { label: "Ja", value: true },
              { label: "Nee", value: false }
            ]}
            selectedValue={incomeResult.deductions.mortgageCosts}
            updateType={"deduction"}
            handleClick={(value, id) =>
            {
              setSelectedButton(id);
              handleButtonClick(value, id, "deduction", ["paidMortgageCosts"])
            }
            }
            fieldId="mortgageCosts"
          />
        )}
      {incomeResult.homeOwner && incomeResult.deductions.mortgageCosts && (
        <InputField
          label="Bedrag"
          header="Hoeveel hypotheekkosten heeft u in dit jaar gemaakt?"
          type="number"
          value={incomeResult.deductions.paidMortgageCosts}
          onChange={(e) => handleDeductionFieldUpdate({ paidMortgageCosts: parseFloat(e.target.value) })}
          validate={validatePositiveNumber}
        />
      )}
        {incomeResult.homeOwner && (
          <InputButtonSet
            header="Heeft u recht op hypotheekrente aftrek?"
            isActive={selectedButton === "mortgageInterestDeduction"}
            options={[
              { label: "Ja", value: true },
              { label: "Nee", value: false }
            ]}
            selectedValue={incomeResult.deductions.mortgageInterestDeduction}
            updateType={"deduction"}
            handleClick={(value, id) =>
            {
              setSelectedButton(id);
              handleButtonClick(value, id, "deduction", ["paidMortgageInterest"])
            }
            }
            fieldId="mortgageInterestDeduction"
          />
        )}
        {incomeResult.homeOwner && incomeResult.ownerOccupiedHome && incomeResult.deductions.mortgageInterestDeduction && (
          <InputField
            label="Bedrag"
            header="Hoeveel heeft u in het afgelopen jaar aan hypotheekrente betaald?"
            type="number"
            value={incomeResult.deductions.paidMortgageInterest}
            onChange={(e) => handleDeductionFieldUpdate({ paidMortgageInterest: parseFloat(e.target.value) })}
            validate={validatePositiveNumber}
          />
        )}
    </div>
  );
}

export default HousingStep;
