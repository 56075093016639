import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceClothingStep({ handleChange, incomeResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="finance-content-container">
      <h2>Stap 10</h2>
      <div>
        <h1>Hoeveel betaalt u aan kleding en schoenen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.clothingCosts.clothingAndShoesCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "clothingCosts.clothingAndShoesCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan overig?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.clothingCosts.clothingAndShoesCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "clothingCosts.clothingAndShoesCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceClothingStep;
