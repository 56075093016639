import "./style/input-button-set.style.css";
import AnimatedPointer from "../animated-pointer-component/animated-pointer.component";
import ToggleButton from "../toggle-button/toggle-button.component";
import { useFocus } from "../../context/focusContext";
import { ReactComponent as InfoIcon } from "../../assets/svg/info-icon.svg";

const InputButtonSet = ({
                      header,
                      value,
                      isActive,
                      options,
                      selectedValue,
                      handleClick,
                      fieldId,
                      updateType
                    }) => {

  const { isFocused } = useFocus();

  return (
    <div>
      <AnimatedPointer isActive={!isFocused && isActive}>
        <h1>
          {header}
          <InfoIcon className="input-info-icon"/>
        </h1>
      </AnimatedPointer>
      <div className="buttons">
        <div className="input-container">
          {options.map(({ label, value }) => (
            <ToggleButton
              key={value}
              label={label}
              isSelected={selectedValue === value}
              onClick={() => handleClick(value, fieldId, updateType)}
              value={value}
              id={fieldId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InputButtonSet;
