// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-ag-grid {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.custom-ag-grid .ag-header {
    background-color: var(--White, #fff);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 8px 0 rgba(16, 26, 66, 0.16);
}

.custom-ag-grid .ag-header-cell {
    padding: 12px;
    text-align: center;
}

.custom-ag-grid .ag-header-cell-label {
    color: var(--Dark, var(--Dark, #101A42));
    font-family: Montserrat, "sans-serif";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

/* Rij-styling */
.custom-ag-grid .ag-row {
    color: var(--Dark, var(--Dark, #101A42));
    font-family: Montserrat, "sans-serif";
    font-size: 14px;
    border-bottom: 1px solid #eee;
}

.custom-ag-grid .ag-cell {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* Status styling */
.custom-ag-grid .ag-cell:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* Status bolletjes */
.status-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

/* Details-knop */
.details-icon {
    width: 100%;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/data-grid-component/style/data-grid.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,yCAAyC;AAC7C;;AAEA,mBAAmB;AACnB;IACI,oCAAoC;IACpC,0BAA0B;IAC1B,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,wCAAwC;IACxC,qCAAqC;IACrC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA,gBAAgB;AAChB;IACI,wCAAwC;IACxC,qCAAqC;IACrC,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA,mBAAmB;AACnB;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA,qBAAqB;AACrB;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA,iBAAiB;AACjB;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".custom-ag-grid {\n    width: 100%;\n    border-radius: 8px;\n    overflow: hidden;\n    background: white;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n/* Header styling */\n.custom-ag-grid .ag-header {\n    background-color: var(--White, #fff);\n    border-radius: 8px 8px 0 0;\n    box-shadow: 0 0 8px 0 rgba(16, 26, 66, 0.16);\n}\n\n.custom-ag-grid .ag-header-cell {\n    padding: 12px;\n    text-align: center;\n}\n\n.custom-ag-grid .ag-header-cell-label {\n    color: var(--Dark, var(--Dark, #101A42));\n    font-family: Montserrat, \"sans-serif\";\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n\n}\n\n/* Rij-styling */\n.custom-ag-grid .ag-row {\n    color: var(--Dark, var(--Dark, #101A42));\n    font-family: Montserrat, \"sans-serif\";\n    font-size: 14px;\n    border-bottom: 1px solid #eee;\n}\n\n.custom-ag-grid .ag-cell {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n/* Status styling */\n.custom-ag-grid .ag-cell:first-child {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n/* Status bolletjes */\n.status-icon {\n    width: 12px;\n    height: 12px;\n    border-radius: 50%;\n}\n\n/* Details-knop */\n.details-icon {\n    width: 100%;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
