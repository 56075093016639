import { useCallback } from "react";

const useDeductionFieldUpdate = (handleFieldUpdate, companyResult) => {
  const handleDeductionFieldUpdate = useCallback(
    (updates) => {
      console.log("DEDUCTIONFIELDUPDATE", updates);
      handleFieldUpdate("deductions", {
        ...companyResult.deductions,
        ...updates,
      });
    },
    [handleFieldUpdate, companyResult.deductions]
  );

  return { handleDeductionFieldUpdate };
};

export default useDeductionFieldUpdate;
