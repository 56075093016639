import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {fetchConfigs} from "../../utils/apiHelpers";
import FormatDate from "../../utils/dateConversion";
import DataGridComponent from "../data-grid-component/data-grid.component";
import LinkButtonComponent from "../link-button-component/link-button.component";

function ConfigOverview() {
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;
  const currentYear = new Date().getFullYear();
  const configButton = p => {
    console.log("P", p.value);
    return <LinkButtonComponent link={`/config/${p.value}`} text={"Inzien"} />
  }
  const [colDefs, setColDefs] = useState([
    { field: "year", flex: 1, headerName: "Jaar" },
    { field: "action", flex: 1, headerName: "Actie", cellRenderer: configButton}
  ]);
  const [configData, setConfigData] = useState(null);

  const generateDataTableData = async (configs) => {
    let tableData = []

    configs.forEach((config) => {
      let dataObject = {
        year: config.year,
        action: config.year
      }
      tableData.push(dataObject)
    })
    setConfigData(tableData)
  }

  const loadConfigs = async () => {
    try {
      const resultsData = await fetchConfigs(url);
      setConfigs(resultsData);
      await generateDataTableData(resultsData);
    } catch (error) {
      // Handle any additional logic if needed
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadConfigs();
  }, []);
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
      <div className="column-full-width center">
        <LinkButtonComponent link={`/config/${currentYear}`} text={"Nieuwe Configuratie"} />
        <DataGridComponent rowData={configData} colDefs={colDefs} />
      </div>
  );
}

export default ConfigOverview;
