import { ReactComponent as PlusIcon } from "../../../assets/svg/transparent-plus.svg";
import { ReactComponent as IntroImage } from "../../../assets/svg/income-tax/icon-logo.svg";
import "../style/net-income-wizard.style.css";
import "./style/intro-step.css";

function IntroStep() {
  return (
    <div className="income-tax-content-container">
      <div className="income-tax-intro-container">
        <h1>Welkom bij de berekentool</h1>
        <div className="income-tax-intro-content">
          <div className="income-tax-intro-text">
            <p>
              Om je zo goed mogelijk te helpen hier een uitleg van wat we van je
              nodig hebben en hoe de tool werkt.
            </p>
            <p>
              De 3 boxen die je zult herkennen van de belastingdienst zullen
              ingevuld moeten worden.
            </p>
            <p>
              Wij bij be-easy hebben deze tool zo gemaakt dat je altijd de
              verder kan gaan wanneer je weer tijd hebt.
            </p>
            <p>
              Iets met het belang van fiscaal partner gegevens nodig hebben om
              de berekening te kunnen maken
            </p>
          </div>
          <div className="income-tax-intro-image">
            <IntroImage />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroStep;
