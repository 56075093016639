import React, { useState, useEffect } from "react";

import "../style/net-income-wizard.style.css";
import "./style/children-step.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import { ReactComponent as InputPointer } from "../../../assets/svg/input-pointer.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default CSS for the date picker
import { nl } from "date-fns/locale";
import InputButtonSet from "../../input-button-set/input-button-set.component";

function ChildrenStep({ handleChange, incomeResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const [selectedDate, setSelectedDate] = useState(
    incomeResult.youngestChildBirthDate
  );
  useEffect(() => {
    setSelectedDate(incomeResult.youngestChildBirthDate);
  }, [incomeResult.youngestChildBirthDate]);

  const handleDateChange = (date) => {
    handleChange("youngestChildBirthDate", date);
  };

  const [selectedButton, setSelectedButton] = useState(incomeResult.children);
  const [isFocused, setIsFocused] = useState(false);

  const handleButtonClick = (value, id) => {
    setIsFocused(false);
    setSelectedButton(id);
    handleFieldUpdate("children", value)
  }
  return (
    <div className="income-tax-content-container">
      <h2>Stap 2</h2>
      <InputButtonSet
        header="Heeft u kinderen?"
        isActive={selectedButton === "children"}
        options={[
          { label: "Ja", value: true },
          { label: "Nee", value: false }
        ]}
        updateType="field"
        selectedValue={incomeResult.children}
        handleClick={handleButtonClick}
        fieldId="children"
      />
      {incomeResult.children && (
          <div className="children-birthdate-picker">
            <div className="input-header">
              {isFocused && (
                  <InputPointer className="input-pointer show"/>
              )}
              <h1>Geboortedatum van uw jongste kind</h1>
            </div>
            {/* TODO - Goed fixen styling */}
            <div>
            <DatePicker
                selected={selectedDate ? new Date(selectedDate) : null}
                onChange={handleDateChange}
                className="date-picker"
                calendarClassName="date-picker-net-income-wizard"
                onFocus={() => {
                  setSelectedButton(false)
                  setIsFocused(true)
                }}
                onBlur={() => setIsFocused(false)}
                onCalendarOpen={() => setIsFocused(true)}  // Trigger focus when calendar opens
                onCalendarClose={() => setIsFocused(false)} // Trigger blur when calendar closes
                showPopperArrow={false}
                placeholderText="30-12-2001"
                locale={nl}
            />
            </div>
          </div>
      )}
    </div>
  );
}

export default ChildrenStep;
