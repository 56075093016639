import {useForm} from "../../hooks/useForm.hook";
import React, {useEffect, useReducer, useState} from "react";
import {useParams} from "react-router-dom";
import {useAuth} from "../../context/authContext";
import axios from "axios";
import IntroStep from "./intro-step/intro-step.component";
import IncomeTaxDescription from "./income-tax-description/income-tax-description.component";
import FiscalPartnerStep from "./fiscal-partner-step/fiscal-partner-step.component";
import ChildrenStep from "./children-step/children-step.component";
import LegalFormStep from "./legal-form-step/legal-form-step.component";
import CompanyRevenueStep from "./company-revenue-step/company-revenue-step.component";
import HousingStep from "./housing-step/housing-step.component";
import MedicalExpensesStep from "./medical-expenses-step/medical-expenses-step.component";
import GiftsStep from "./gifts-step/gifts-step.component";
import PartnerAlimonyStep from "./partner-alimony-step/partner-alimony-step.component";
import IncomeResult from "./income-result/income-result.component";
import IncomeTaxNavigation from "./income-tax-navigation/income-tax-navigation.component";
import TravelExpensesStep from "./travel-expenses-step/travel-expenses-step.component";
import AnnuityStep from "./annuity-step/annuity-step.component";
import DividendStep from "./dividend-step/dividend-step.component";
import CapitalStep from "./capital-step/capital-step.component";
import DisabilityInsuranceStep from "./disability-insurance-step/disability-insurance-step.component";
import stepCalculations from "../../utils/stepCalculations";
import {
  fetchCompanyData,
  fetchConfig,
  fetchIncomeWizardProgress,
  fetchSingleIncomeResultData,
} from "../../utils/apiHelpers";

const formReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "updateField":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

function NetIncomeWizard() {
  const {currentUser} = useAuth();
  const currentYear = new Date().getFullYear();
  const {incomeResultId} = useParams();

  const introTitle = "Checklist benodigdheden";
  const introContent =
    "Klik tijdens het proces op de vraag om een korte toelichting van de onderwerpen te krijgen.";

  const stepComponentMapping = {
    FiscalPartnerStep: FiscalPartnerStep,
    ChildrenStep: ChildrenStep,
    LegalFormStep: LegalFormStep,
    CompanyRevenueStep: CompanyRevenueStep,
    DisabilityInsuranceStep: DisabilityInsuranceStep,
    HousingStep: HousingStep,
    MedicalExpensesStep: MedicalExpensesStep,
    GiftsStep: GiftsStep,
    PartnerAlimonyStep: PartnerAlimonyStep,
    TravelExpensesStep: TravelExpensesStep,
    AnnuityStep: AnnuityStep,
    DividendStep: DividendStep,
    CapitalStep: CapitalStep,
  };

  const initialFormState = {
    //NOTE - Wizard settings
    //TODO - Uit een database halen en aanpasbaar maken.
    currentStep: 0,
    showNextButton: true,
    showPrevButton: false,
    categories: {
      box_1: {
        title: "Box 1",
        subTitle: "Inkomen uit werk en woning",
        steps: {
          fiscalPartner: {
            order: 1,
            title: "Fiscaal partner",
            component: "FiscalPartnerStep",
            helper_title: "Fiscaal partner uitnodigen",
            helper_content:
              "Gebruik de button om je fiscale partner uit te nodigen.",
          },
          children: {
            order: 2,
            title: "Kinderen",
            component: "ChildrenStep",
            helper_title: "Kinderen",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          legalForm: {
            order: 3,
            title: "Eenmanszaak",
            component: "LegalFormStep",
            helper_title: "Rechtsvorm",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          companyRevenue: {
            order: 4,
            title: "Omzet/kosten",
            component: "CompanyRevenueStep",
            helper_title: "Omzet en kosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          disabilityInsuranc: {
            order: 5,
            title: "AOV",
            component: "DisabilityInsuranceStep",
            helper_title: "AOV",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          housing: {
            order: 6,
            title: "Woning",
            component: "HousingStep",
            helper_title: "Woning",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          medicalExpenses: {
            order: 7,
            title: "Ziektekosten",
            component: "MedicalExpensesStep",
            helper_title: "Aftrekbare ziektekosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          gifts: {
            order: 8,
            title: "Giften",
            component: "GiftsStep",
            helper_title: "Geschonken giften",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          partnerAlimony: {
            order: 9,
            title: "Partneralimentatie",
            component: "PartnerAlimonyStep",
            helper_title: "Betaalde alimentatie",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          travelExpenses: {
            order: 10,
            title: "Reiskosten",
            component: "TravelExpensesStep",
            helper_title: "Aftrekbare reiskosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          annuity: {
            order: 11,
            title: "Lijfrente",
            component: "AnnuityStep",
            helper_title: "Aftrekbare lijfrente producten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
      box_2: {
        title: "Box 2",
        subTitle: "Dividend",
        steps: {
          dividend: {
            order: 12,
            title: "Uitgekeerd dividend",
            component: "DividendStep",
            helper_title: "Dividend uitkeren",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
      box_3: {
        title: "Box 3",
        subTitle: "Kapitaal",
        steps: {
          capital: {
            order: 13,
            title: "Vermogen",
            component: "CapitalStep",
            helper_title: "Belast vermogen",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
    },
  };
  //NOTE - Company result settings
  const initialIncomeResult = {
    differentiatedRevenue: {
      January: 0,
      February: 0,
      March: 0,
      April: 0,
      May: 0,
      June: 0,
      July: 0,
      August: 0,
      September: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    deductions: {
      mortgageInterestDeduction: false,
      paidMortgageInterest: 0,
      mortgageCosts: false,
      paidMortgageCosts: 0,
      notionalRentalValue: false,
      medicalExpenses: false,
      paidMedicalExpenses: 0,
      gifts: false,
      paidGifts: 0,
      partnerAlimony: false,
      paidPartnerAlimony: 0,
      travelExpenses: false,
      paidTravelExpenses: 0,
      annuity: false,
      paidAnnuity: 0,
      disabilityInsurance: false,
      paidDisabilityInsurance: 0,
    },
    companyYear: new Date().getFullYear(),
    companyId: currentUser.companyIds[0],
    starterDeductionEligible: false,
    year: currentYear,
    dividend: false,
    dividendPaid: 0,
    grossExpectedRevenue: 0,
    revenueDifferentiatesMonthly: false,
    grossAverageMonthlyRevenue: 0,
    grossExpectedCosts: 0,
    grossAverageMonthlyCosts: 0,
    grossExpectedInvestments: 0,
    grossIncomeMiscellaneous: 0,
    wozValue: 0,
    hoursWorkingAsEntrepeneur: 1225,
    hoursWorkingAsEntrepeneurPercentage: 0,
    fiscalPartner: false,
    children: false,
    youngestChildBirthDate: null,
    isEntrepeneur: false,
    mainUserIsEntrepeneur: false,
    fiscalPartnerIsEntrepeneur: false,
    homeOwner: false,
    employee: false,
    ownerOccupiedHome: false,
    grossIncomeEmployment: 0,
    incomeAfterTax: 0,
    capitalOverTaxFreeLimit: false,
  };

  const [incomeTaxConfig, setIncomeTaxConfig] = useState(null);
  const [incomeResult, dispatch] = useReducer(
    formReducer,
    initialIncomeResult
  );
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);

  const url = process.env.REACT_APP_API_BASE_URL;

  const loadIncomeResultData = async () => {
    try {
      if (incomeResultId === 'new') {
        console.log("Nieuwe berekening, geen fetch nodig");
        dispatch({ type: "update", payload: initialIncomeResult });
        return;
      }

      const resultData = await fetchSingleIncomeResultData(url, currentUser.userId, incomeResultId);

      if (!resultData || resultData.length === 0) {
        dispatch({ type: "update", payload: initialIncomeResult });
      } else {
        dispatch({ type: "update", payload: resultData });
      }
    } catch (error) {
      console.error("Fout bij ophalen inkomensresultaat:", error);
    } finally {
      setLoading(false);
    }
  };


  const loadCompanyData = async () => {
    try {
      const resultData = await fetchCompanyData(url, currentUser.companyIds[0]);
      setCompany(resultData)
    } catch (error) {
      //TODO - Handle any additional logic if needed
    } finally {
      setLoading(false);
    }
  }

  const loadConfig = async () => {
    try {
      const resultsData = await fetchConfig(url, incomeResult.year);
      setIncomeTaxConfig(resultsData);
    } catch (error) {
      //TODO - Handle any additional logic if needed
    } finally {
      setLoading(false);
    }
  }

  const determineLastStep = (progress) => {
    const activeStep = Object.values(progress).find(step => step.status === 'active');
    if (activeStep) return activeStep.order;

    return Object.values(progress).reduce((highestStep, step) => {
      return step.status === 'done' ? Math.max(highestStep, step.order) : highestStep;
    }, 0);
  };

  const loadProgress = async () => {
    try {
      if (incomeResultId === 'new') {
        console.log("Nieuwe berekening, geen fetch nodig voor voortgang.");
        const savedProgress = JSON.parse(localStorage.getItem("wizardProgress")) || initialProgress();
        setProgress(savedProgress);

        const lastStep = determineLastStep(savedProgress);
        handleStepChange(lastStep || 0);
        return;
      }

      const response = await fetchIncomeWizardProgress(url, currentUser.userId, incomeResultId);

      if (response) {
        setProgress(response);
        localStorage.setItem("wizardProgress", JSON.stringify(response));

        const lastStep = determineLastStep(response);
        handleStepChange(lastStep || 0);
      } else {
        const savedProgress = JSON.parse(localStorage.getItem("wizardProgress"));
        setProgress(savedProgress || initialProgress());

        const lastStep = determineLastStep(savedProgress);
        handleStepChange(lastStep || 0);
      }
    } catch (error) {
      console.error("Fout bij het laden van de voortgang:", error);
      const savedProgress = JSON.parse(localStorage.getItem("wizardProgress"));
      setProgress(savedProgress || initialProgress());

      const lastStep = determineLastStep(savedProgress);
      handleStepChange(lastStep || 0);
    }
  };

  useEffect(() => {
    loadCompanyData();
    loadIncomeResultData();
    loadConfig();
    loadProgress();
  }, []);

  const [formState, handleChange] = useForm(initialFormState);

  const initialProgress = () => {
    const progress = {};
    Object.entries(formState.categories).forEach(([boxKey, boxValue]) => {
      Object.entries(boxValue.steps).forEach(([stepKey, stepValue]) => {
        progress[stepKey] = {
          status: "not-started", // Mogelijke waarden: "not-started", "active", "done"
          order: stepValue.order,
        };
      });
    });
    return progress;
  };

  const [progress, setProgress] =  useState(initialProgress);

  useEffect(() => {

  }, [progress]);

  useEffect(() => {
    if (progress) {
      localStorage.setItem("wizardProgress", JSON.stringify(progress));
    } else if (!progress || Object.keys(progress).length === 0) {
      handleStepChange(0);
    }
  }, [progress]);

  const updateLocalProgress = (updatedProgress) => {
    localStorage.setItem("wizardProgress", JSON.stringify(updatedProgress));
    setProgress(updatedProgress);
  };

  const syncProgressToBackend = async () => {
    try {
      //TODO - Verplaatsen naar APIHelpers
      await axios.post(`${url}/incomeresults/${currentUser.userId}/${incomeResultId}/progress`, {
        userId: currentUser.userId,
        progress,
      },
        {
          withCredentials: true,
        });
    } catch (error) {
      console.error("Error syncing progress:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      syncProgressToBackend();
    }, 30000);
    return () => clearInterval(interval);
  }, [progress]);

  const getCurrentStepComponent = () => {
    for (const [boxKey, boxValue] of Object.entries(formState.categories)) {
      for (const [stepKey, stepValue] of Object.entries(boxValue.steps)) {
        if (stepValue.order === formState.currentStep) {
          return stepValue.component;
        }
      }
    }
    console.log(
      "No matching component found for current step:",
      formState.currentStep
    );
    return null;
  };

  const handleStepChange = (value) => {
    if (!progress || Object.keys(progress).length === 0) {
      setProgress(initialProgress());
    }

    const stepKey = Object.entries(progress).find(
      ([key, step]) => step.order === value
    )?.[0];

    if (!stepKey) {
      console.error("Fout: Geen geldige stepKey gevonden voor waarde:", value);
      return;
    }

    const updatedProgress = { ...progress };
    updatedProgress[stepKey] = { ...updatedProgress[stepKey], status: "active" };

    Object.keys(updatedProgress).forEach((key) => {
      if (updatedProgress[key].order < value) {
        updatedProgress[key].status = "done";
      } else if (updatedProgress[key].order > value) {
        updatedProgress[key].status = "not-started";
      }
    });

    setProgress(updatedProgress);
    updateLocalProgress(updatedProgress);
    handleChange("currentStep", value);
  };

  const handleFormChange = (field, value) => {
    dispatch({type: "updateField", field, value});
  };

  useEffect(() => {
    const currentComponent = getCurrentStepComponent();
    setCurrentComponent(currentComponent);
  }, [formState.currentStep]);

  const handleSubmit = async () => {
    try {
      if (incomeResultId !== 'new') {
        const patchUrl = `${url}/incomeresults/${currentUser.userId}/${incomeResultId}`;
        const patchResponse = await axios.patch(
          patchUrl,
          {
            results: incomeResult,
            progress: progress,
          },
          {
            withCredentials: true,
          }
        );
        dispatch({type: "update", payload: patchResponse.data.data.result});
        setIsSaveClicked(true);
      } else if (incomeResultId === 'new') {
        const postUrl = `${url}/incomeresults/${currentUser.userId}/new`;
        const postResponse = await axios.post(
          postUrl,
          {
            results: incomeResult,
          },
          {
            withCredentials: true,
          }
        );
        dispatch({type: "update", payload: postResponse.data.data.result});
        setIsSaveClicked(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleReset = () => {
    setIsSaveClicked(false);
  };

  const handleBtnSaveClick = () => {
    handleSubmit();
  };

  const getCurrentHelperContent = () => {
    for (const [boxKey, boxValue] of Object.entries(formState.categories)) {
      for (const [stepKey, stepValue] of Object.entries(boxValue.steps)) {
        if (stepValue.order === formState.currentStep) {
          return {
            title: stepValue.helper_title,
            content: stepValue.helper_content,
          };
        }
      }
    }
    return { title: "", content: "" };
  };

  const prevStep = () => {
    const prevStep = formState.currentStep - 1;
    handleStepChange(prevStep);
  };

  const nextStep = () => {
    const nextStep = formState.currentStep + 1;
    handleStepChange(nextStep);
  };

  const totalSteps = stepCalculations.calculateTotalSteps(formState.categories);

  const { title, content } = getCurrentHelperContent();

  if (loading) {
    return <p>Data inladen...</p>;
  }

  if (!incomeTaxConfig) {
    return <p>Geen inkomstenbelasting configuratie gevonden.</p>;
  }

  if (!incomeResult) {
    return <p>Geen bedrijfsresultaten gevonden.</p>;
  }
  const DynamicStepComponent = stepComponentMapping[getCurrentStepComponent()];
  console.log("CR", incomeResult);
  console.log("CONFIG", incomeTaxConfig);
  return (
    <div>
      <div className="test">
        <h1>je moeder</h1>
      </div>

  <div className="container-3-column income-tax-container">
    <div className="column-1-wide income-tax-wizard-navigation">
          <IncomeTaxNavigation
            categories={formState.categories}
            currentStep={formState.currentStep}
            handleChange={handleStepChange}
            fiscalPartner={incomeResult.fiscalPartner}
            progress={progress}
          />
        </div>
        <div className="column-2-wide income-tax-wizard-content">
          {console.log(
            "Rendering currentComponent:",
            currentComponent,
            "at step",
            formState.currentStep
          )}
          {!isSaveClicked && formState.currentStep === 0 && <IntroStep/>}
          {!isSaveClicked &&
            formState.currentStep > 0 &&
            DynamicStepComponent && (
              <DynamicStepComponent
                handleChange={handleFormChange}
                incomeResult={incomeResult}
                incomeTaxConfig={incomeTaxConfig}
              />
            )}
          {isSaveClicked && (
            <IncomeResult
              incomeResult={incomeResult}
            />
          )}

          <div className=".column-full-width income-tax-navigation">
            {!isSaveClicked && formState.currentStep > 1 && (
              <button className="btn-previous" onClick={prevStep}>
                Vorige
              </button>
            )}
            {!isSaveClicked &&
              formState.currentStep < totalSteps &&
              formState.currentStep !== 0 && (
                <button className="btn-next" onClick={nextStep}>
                  Volgende
                </button>
              )}
            {!isSaveClicked && formState.currentStep === 0 && (
              <button className="btn-next" onClick={nextStep}>
                Start
              </button>
            )}
            {!isSaveClicked && formState.currentStep === totalSteps && (
              <button className="btn-save" onClick={handleBtnSaveClick}>
                Opslaan
              </button>
            )}
            <div>
              {isSaveClicked && (
                <button className="btn-reset" onClick={handleReset}>
                  Terug naar formulier
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="column-1-wide income-tax-wizard-description">
          {formState.currentStep === 0 && (
            <IncomeTaxDescription title={introTitle} content={introContent}/>
          )}
          {formState.currentStep > 0 && (
            <IncomeTaxDescription title={title} content={content}/>
          )}
        </div>
      </div>
    </div>
      );
      }

      export default NetIncomeWizard;
