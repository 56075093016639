import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceHouseholdCostsStep({
  handleChange,
  incomeResult,
  financeResult,
}) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="finance-content-container">
      <h2>Stap 14</h2>
      <div>
        <h1>Hoeveel betaalt u aan voeding?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.foodCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.foodCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan Was- en schoonmaakmiddelen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.cleaningProductsCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.cleaningProductsCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan persoonlijke verzorging?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.personalHygieneCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.personalHygieneCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan Huishoudelijke dienstverlening?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.domesticServicesCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.domesticServicesCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan huisdieren?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.petsCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.petsCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan roken?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.smokingCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.smokingCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan diversen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdCosts.miscCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdCosts.miscCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceHouseholdCostsStep;
