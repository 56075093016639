import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceEducationStep({ handleChange, incomeResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="finance-content-container">
      <h2>Stap 7</h2>
      <div>
      {incomeResult.children && (
        <div>
            <h1>Hoeveel betaalt u aan school- en studiekosten voor kinderen?</h1>
            <div className="input-container">
              <input
                type="number"
                value={financeResult.educationCosts.childStudySupportCosts}
                onChange={(e) =>
                  handleFieldUpdate(
                    "educationCosts.childStudySupportCosts",
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
        </div>
      )}
      </div>
      <div>
        <h1>Hoeveel betaalt u aan studiekosten voor volwassenen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.educationCosts.adultStudyCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "educationCosts.adultStudyCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceEducationStep;
