import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoutButton from "../logout-button/logout-button.component";
import "./style/top-navigation.css";
import { ReactComponent as Logo } from "../../assets/svg/top-logo.svg";
import { useAuth } from "../../context/authContext";

const TopNavigation = ({ user }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => () => {
    navigate(path);
  };

  const { currentUser } = useAuth();

  return (
    <nav className="container-3-column top-navigation-container">
      <div className="column-1-wide top-logo">
        <Logo />
      </div>
      <div className="column-2-wide top-navigation-items">
        <Link to="/calculation">berekeningen</Link>
        {currentUser && (currentUser.role === 'admin' || currentUser.role === 'advisor') && (
          <Link to="/configurations">configuraties</Link>
        )}
        {currentUser && currentUser.role === "advisor" && (
          <Link to="/advisor/customers">klanten</Link>
        )}
        {currentUser && currentUser.role !== 'fiscalpartner' && (
          <Link to="/finance">kasboek</Link>
        )}
        <Link to="/about">over ons</Link>
      </div>
      {user === null && (
        <div className="column-1-wide top-navigation-auth">
          <button
            onClick={navigateTo("/register")}
            className="auth-button register"
          >
            Registreer
          </button>
          <button onClick={navigateTo("/login")} className="auth-button login">
            Login
          </button>
        </div>
      )}
      {user !== null && (
        <div className="column-1-wide top-navigation-auth">
          <button onClick={navigateTo("/calculation")} className="auth-button">
            Dashboard
          </button>
          <LogoutButton />
        </div>
      )}
    </nav>
  );
};

export default TopNavigation;
