import "./style/input-field.style.css";
import { useState } from "react";
import { useFocus } from "../../context/focusContext";
import { ReactComponent as InputPointer } from "../../assets/svg/input-pointer.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/info-icon.svg";

const InputField = ({
                      label = '',
                      header,
                      type = "text",
                      value,
                      onChange,
                      onFocus,
                      onBlur,
                      validate,
                      errorMessage,
                      infoIcon = 'true',
                      inputHeaderClass = "input-header",
                      inputPointerClass = "input-pointer show",
                      inputInfoIconClass = "input-info-icon",
                      inputContainerClass = "input-container",
                      buttonText = "",
                      buttonOnClick = null,
                      buttonContainerClass = "input-button-container",
                      buttonClass = "input-button"
                    }) => {
  const { isFocused, handleFocus, handleBlur: contextBlur } = useFocus();
  const [error, setError] = useState("");

  const handleBlur = (e) => {
    contextBlur();
    if (validate) {
      const validationError = validate(e.target.value);
      setError(validationError);
    }
  };

  return (
    <div>
      <div className={inputHeaderClass}>
        {isFocused && <InputPointer className={inputPointerClass}/>}
        <h1>
          {header}
          {infoIcon === 'true' && (
            <InfoIcon className={inputInfoIconClass}/>
          )}
        </h1>
      </div>
      <div className={inputContainerClass}>
        {label !== '' && (
          <label>{label}</label>
        )}
        <input
          type={type}
          value={value}
          onChange={(e) => {
            onChange(e);
            if (error) {
              setError("");
            }
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {buttonText !== "" && buttonOnClick !== null && (
          <div className={buttonContainerClass}>
            <button className={buttonClass} onClick={buttonOnClick}>{buttonText}</button>
          </div>
        )}
        {error && <p className="error-message">{errorMessage || error}</p>}
      </div>
    </div>
  );
};

export default InputField;
