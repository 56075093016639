import React from "react";
import { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import { useAuth } from "../../context/authContext";
import {fetchCustomer} from "../../utils/apiHelpers";
import DataGridComponent from "../data-grid-component/data-grid.component";
import LinkButtonComponent from "../link-button-component/link-button.component";
import FormatDate from "../../utils/dateConversion";

function CustomerDetail() {
    const url = process.env.REACT_APP_API_BASE_URL;
    const {currentUser} = useAuth();
    const currentYear = new Date().getFullYear();
    const {customerId} = useParams();
    console.log("CustomerID", customerId);
    console.log("CustomerURL", url)

    const [loading, setLoading] = useState(true);
    const [customerData, setCustomerData] = useState(null);
    const [customerDatatableData, setCustomerDatatableData] = useState(null);
    const [customerCompanyData, setCustomerCompanyData] = useState(null);
    const [customerCompanyResultData, setCustomerCompanyResultData] = useState(null);
    const [customerFinanceOverviewData, setcustomerFinanceOverviewData] = useState(null);


    const loadCustomer = async () => {
        try {
            const resultsData = await fetchCustomer(url, customerId);
            setCustomerData(resultsData);
            await(generateCustomerDatagridData(resultsData.customer))
            await(generateCustomerCompanyData(resultsData.company))
            await(generateCustomerCompanyResultsDatagridData(resultsData.incomeResults))
            await (generateCustomerFinanceOverviewDataTableData(resultsData.financeOverviews))
        } catch (error) {
            // Handle any additional logic if needed
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCustomer();
    }, []);

    const generateCustomerDatagridData = async (customer) => {
        let tableData = []
        let dataObject = {
            name: customer.firstName + " " + customer.lastName,
            email: customer.email,
            phoneNumber: customer.phoneNumber,
            address: customer.address,
            zipcode: customer.zipcode,
            city: customer.city,
        }
        tableData.push(dataObject)
        setCustomerDatatableData(tableData)
    }

    const [customerDataColDefs, setCustomerColDefs] = useState([
        {field: "name", flex: 1, headerName: "Naam"},
        {field: "email", flex: 1, headerName: "Email"},
        {field: "phoneNumber", flex: 1, headerName: "Telefoonnummer"},
        {field: "address", flex: 1, headerName: "Adres"},
        {field: "zipcode", flex: 1, headerName: "Postcode}"},
        {field: "city", flex: 1, headerName: "Woonplaats"},
    ]);

    const generateCustomerCompanyData = async (company) => {
        let tableData = []
        let dataObject = {
            cocNumber: company.cocNumber,
            name: company.name,
            legalForm: company.legalForm,
            address: company.address,
            zipcode: company.zipcode,
            city: company.city
        }
        tableData.push(dataObject)
        setCustomerCompanyData(tableData)
    }

    const [customerCompanyDataColDefs, setCustomerCompanyDataColDefs] = useState([
        {field: "cocNumber", flex: 1, headerName: "KvK Nummer"},
        {field: "name", flex: 1, headerName: "Bedrijfsnaam"},
        {field: "legalForm", flex: 1, headerName: "Rechtsvorm"},
        {field: "address", flex: 1, headerName: "Adres"},
        {field: "zipcode", flex: 1, headerName: "Postcode"},
        {field: "city", flex: 1, headerName: "Vestigingsplaats"},
    ]);

    const customerCompanyResultButton = p => {
        console.log("P", p.value);
        const { id, year } = p.value
        return <LinkButtonComponent link={`/calculation/${id}/${year}`} text={"Inzien"} />
    }

    const generateCustomerCompanyResultsDatagridData = async (companyResults) => {
        let tableData = []
        console.log("RESULTS",companyResults)

        companyResults.forEach((result) => {
            let dataObject = {
                year: result.year,
                entrepeneur: result.isEntrepeneur,
                fiscalPartner: result.fiscalPartner,
                homeOwner: result.homeOwner,
                children: result.children,
                grossExpectedRevenue: parseInt(result.grossExpectedRevenue),
                grossExpectedCosts: parseInt(result.grossExpectedCosts),
                incomeAfterTax: parseInt(result.incomeAfterTax),
                action: { id: result._id, year: result.year }
            }
            tableData.push(dataObject)
        })
        setCustomerCompanyResultData(tableData)
    }

    const [customerCompanyResultDataColDefs, setCustomerCompanyResultDataColDefs] = useState([
        {field: "year", flex: 1, headerName: "Jaar"},
        {field: "entrepeneur", flex: 1, headerName: "Ondernemer"},
        {field: "fiscalPartner", flex: 1, headerName: "Fiscaal partner"},
        {field: "homeOwner", flex: 1, headerName: "Huiseigenaar"},
        {field: "children", flex: 1, headerName: "Kinderen"},
        {field: "grossExpectedRevenue", flex: 1, headerName: "Jaarlijkse Omzet", valueFormatter: p => '€' + p.value },
        {field: "grossExpectedCosts", flex: 1, headerName: "Jaarlijkse Kosten", valueFormatter: p => '€' + p.value},
        {field: "incomeAfterTax", flex: 1, headerName: "Jaarlijkse Inkomsten (netto)", valueFormatter: p => '€' + p.value},
        {field: "action", flex: 1, headerName: "Actie", cellRenderer: customerCompanyResultButton},
    ]);

    const customerFinanceOverviewButton = p => {
        console.log("P", p.value);
        return <LinkButtonComponent link={`/advisor/customers/${p.value}`} text={"Inzien"} />
    }

    const generateCustomerFinanceOverviewDataTableData = async (financeOverviews) => {
        let tableData = []

        financeOverviews.forEach((financeOverview) => {
            let dataObject = {
                year: financeOverview.year,
                action: financeOverview._id
            }
            tableData.push(dataObject)
        })
        setcustomerFinanceOverviewData(tableData)
    }

    const [customerFinanceOverviewDataColDefs, setColDefs] = useState([
        {field: "year", flex: 1, headerName: "Jaar"},
        {field: "action", flex: 1, headerName: "Actie", cellRenderer: customerFinanceOverviewButton},
    ]);

    if (loading) {
        return <p>Loading...</p>;
    }

    console.log("customerData", customerData)
    return (
        <div className="column-full-width center">
            <h1>Klantoverzicht {customerData.firstName} {customerData.lastName}</h1>
            <div>
                <h2>Gegevens</h2>
                <DataGridComponent rowData={customerDatatableData} colDefs={customerDataColDefs}/>
            </div>
            <div>
                <h2>Bedrijfsgegevens</h2>
                <DataGridComponent rowData={customerCompanyData} colDefs={customerCompanyDataColDefs}/>
            </div>
            <div>
                <h2>Bedrijfsresultaten</h2>
                <LinkButtonComponent link={`/calculation/${customerData.customer._id}/${currentYear}`} text={"Nieuwe berekening"} />
                <DataGridComponent rowData={customerCompanyResultData} colDefs={customerCompanyResultDataColDefs}/>
            </div>
            <div>
                <h2>Kasboeken</h2>
                <DataGridComponent rowData={customerFinanceOverviewData} colDefs={customerFinanceOverviewDataColDefs}/>
            </div>
        </div>
    );
}

export default CustomerDetail;
