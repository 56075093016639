import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { fetchFinanceDataForUser } from "../../utils/apiHelpers";
import LinkButtonComponent from "../link-button-component/link-button.component";
import DataGridComponent from "../data-grid-component/data-grid.component";

function FinanceOverview() {
  const [loading, setLoading] = useState(true);
  const [financeResults, setFinanceResults] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;
  const { currentUser } = useAuth();
  const financeButton = p => {
    return <LinkButtonComponent link={`/finance/${p.value}`} text={"Inzien"} />
  }
  const [colDefs, setColDefs] = useState([
    { field: "id", flex: 1, headerName: "ID" },
    { field: "action", flex: 1, headerName: "Actie", cellRenderer: financeButton}
  ]);
  const [configData, setConfigData] = useState(null);

  const generateDataTableData = async (financeOverviews) => {
    let tableData = []

    financeOverviews.forEach((config) => {
      let dataObject = {
        id: config._id,
        action: config._id
      }
      tableData.push(dataObject)
    })
    setConfigData(tableData)
  }

  const loadFinanceData = async () => {
    try {
      const resultsData = await fetchFinanceDataForUser(url, currentUser.userId);
      setFinanceResults(resultsData);
      await generateDataTableData(resultsData);
    } catch (error) {
      // Handle any additional logic if needed
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadFinanceData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="column-full-width center">
      <LinkButtonComponent link={`/finance/${currentUser.userId}`} text={"Nieuw kasboek"} />
      <DataGridComponent rowData={configData} colDefs={colDefs} />
    </div>
  );
}
export default FinanceOverview;
