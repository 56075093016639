import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceIncomeStep({ handleChange, incomeResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

    const handleInputChange = (field) => (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            handleFieldUpdate(field, value);
        } else {
            handleFieldUpdate(field, 0);
        }
    };
  console.log("INCOME RESULT", incomeResult);
  return (
      <div className="finance-content-container">
          <h2>Stap 3</h2>
          <div>
              <h1>Wat is uw inkomen?</h1>
              <div className="input-container">
                  <input
                      type="number"
                      //TODO - Fixen dat de incomeAfterTax in de finance result komt.
                      //TODO - Fixen dat er een error word weergeven als er geen data is.
                      value={(parseFloat(incomeResult.incomeAfterTax/12)).toFixed(2)}
                      onChange={(handleInputChange("mainIncome"))}
                  />
              </div>
          </div>
          <div>
              <h1>Wat is het inkomen van uw partner?</h1>
              <div className="input-container">
                  <input
                      type="number"
                      value={financeResult.partnerIncome}
                      onChange={(handleInputChange("partnerIncome"))}
                  />
              </div>
          </div>
          <div>
              {incomeResult.children && (
              <div>
                  <h1>Hoeveel ontvangt u aan kinderopvangtoeslag?</h1>
                  <div className="input-container">
                      <input
                          type="number"
                          value={financeResult.childCareAllowance}
                          onChange={(handleInputChange("childCareAllowance"))}
                      />
                  </div>
              </div>
                )}
          </div>
      </div>
  );
}

export default FinanceIncomeStep;
